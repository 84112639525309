import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { v4 as uuidv4 } from 'uuid';
import { CHAT_MESSAGE_BOX_COLOR, THEME_COLOR, THEME_LIGHT_COLOR, UPLOAD_API_URL } from './src/config/constants';
import { uploadFile } from './src/service/slateService';


const UploadContentAI = ({ clientID, accessToken, clippedFileData, isTextFocused, _sketch, setOpenGPT, _selectTool }) => {
    const [messageInput, setMessageInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [threadId, setThreadId] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);  // State for the attached image
    const chatContainerRef = React.useRef(null);
    const inputRef = React.useRef(null);
    const loaderRef = React.useRef(null);

    useEffect(() => {
        if (clippedFileData) {
            setSelectedImage(clippedFileData)
        }
    }, [clippedFileData])

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    const appendMessageToDOM = (message, type) => {
        const messageContainer = document.createElement('div');
        messageContainer.style.display = 'flex';
        messageContainer.style.flexDirection = 'column';
        messageContainer.style.alignItems = type === 'userMessage' ? 'flex-end' : 'flex-start';
        messageContainer.style.margin = '10px 0';

        const messageElement = document.createElement('div');
        messageElement.style.padding = '10px 15px';
        messageElement.style.borderRadius = '10px';  // Adjust border-radius to 10px
        messageElement.style.maxWidth = '75%';
        messageElement.style.wordWrap = 'break-word';
        messageElement.style.fontSize = '16px';
        messageElement.style.lineHeight = '1.4';

        if (type === 'userMessage') {
            messageElement.style.backgroundColor = THEME_COLOR;
            messageElement.style.color = '#fff';
            messageElement.style.alignSelf = 'flex-end';
        } else {
            messageElement.style.backgroundColor = THEME_COLOR;
            messageElement.style.color = '#000000';
            messageElement.style.alignSelf = 'flex-start';
            const loader = document.createElement('div');
            loader.className = 'loader';
            messageElement.appendChild(loader);
            loaderRef.current = loader;
            loaderRef.current.style.display = 'block';
        }

        // Check for 'fileUrl' pattern
        const fileUrlPattern = /; fileUrl: (https:\/\/.+)/;
        const match = message.match(fileUrlPattern);
        let textMessage = message;
        let fileUrl = '';

        if (match) {
            textMessage = message.split(';')[0].trim();  // Extract text before the semicolon and trim it
            fileUrl = match[1];  // Extract the file URL
        }

        // Append image if fileUrl exists
        if (fileUrl) {
            const imageElement = document.createElement('img');
            imageElement.src = fileUrl;
            imageElement.alt = 'Uploaded image';
            imageElement.style.maxWidth = '150px';  // Set your desired max width
            imageElement.style.maxHeight = '150px';
            imageElement.style.borderRadius = '10px';  // Set image border-radius to 10px
            imageElement.style.marginBottom = '10px';
            imageElement.style.boxShadow = '0px 8px 16px rgba(0, 0, 0, 0.3)';
            messageContainer.appendChild(imageElement);  // Append image above the message
        }

        // Add text message if it's not empty
        if (textMessage) {
            messageElement.textContent = textMessage;
            messageContainer.appendChild(messageElement);  // Append text message to the container
        }

        chatContainerRef.current.appendChild(messageContainer);  // Append the container to the chat

        // If GPT/assistant message, add the paste icon using React Portal


        scrollToBottom();
    };

    const handlePasteToWhiteboard = (message) => {
        const cleanedMessage = message.replace(/📋Paste to whiteboard/, '').trim();
        const formattedMessage = cleanedMessage.replace(/(.{80})/g, '$1\n');  // Insert \n after every 100 characters
        console.log('Pasting to whiteboard:', formattedMessage);
        console.log('Pasting to whiteboard:', formattedMessage);
        if (_sketch && _sketch.current) {
            _sketch.current.addText(formattedMessage)
            _selectTool({ target: { value: "Select" } })
            setOpenGPT(false)
        }
    };

    const createTooltip = (button, text) => {
        const tooltip = document.createElement('span');
        tooltip.textContent = text;
        tooltip.style.visibility = 'hidden';
        tooltip.style.backgroundColor = '#525252';
        tooltip.style.color = '#fff';
        tooltip.style.textAlign = 'center';
        tooltip.style.padding = '5px';
        tooltip.style.borderRadius = '5px';
        tooltip.style.position = 'absolute';
        tooltip.style.bottom = '125%';
        tooltip.style.left = '50%';
        tooltip.style.transform = 'translateX(-50%)';
        tooltip.style.whiteSpace = 'nowrap';
        tooltip.style.fontSize = '12px';

        button.style.position = 'relative';
        button.appendChild(tooltip);

        button.addEventListener('mouseover', () => {
            tooltip.style.visibility = 'visible';
        });

        button.addEventListener('mouseout', () => {
            tooltip.style.visibility = 'hidden';
        });
    };



    const handleSendMessage = async () => {
        if (!messageInput.trim() && !selectedImage) return;

        let fileUrl = '';
        let messageToSend
        if (selectedImage) {
            fileUrl = await handleUploadFile(selectedImage); // Upload file and get URL
            console.log(`File uploaded: ${fileUrl}`);
            messageToSend = messageInput.trim() + '; fileUrl: ' + fileUrl; // Create message with URL
        }
        else {
            messageToSend = messageInput.trim();
        }
        console.log(`Message to send: "${messageToSend}"`);

        // Add logic to send the message and image URL to the server
        const formData = new FormData();
        formData.append('message', messageToSend);
        // Append user message directly to the DOM
        appendMessageToDOM(messageToSend, 'userMessage');
        console.log(`User message sent: "${messageToSend}"`);

        // Clear the input field
        setMessageInput('');

        // Add an empty assistant message to the DOM
        const assistantMessageElement = document.createElement('div');
        // assistantMessageElement.style.padding = '10px 15px';
        // assistantMessageElement.style.margin = '10px 0';
        // assistantMessageElement.style.borderRadius = '20px';
        // assistantMessageElement.style.maxWidth = '75%';
        // assistantMessageElement.style.wordWrap = 'break-word';
        // assistantMessageElement.style.fontSize = '16px';
        // assistantMessageElement.style.lineHeight = '1.4';
        // assistantMessageElement.style.backgroundColor = '#e9ecef';
        // assistantMessageElement.style.color = '#333';
        // assistantMessageElement.style.alignSelf = 'flex-start';
        assistantMessageElement.textContent = ''; // This will be updated with chunks
        const loader = document.createElement('div');
        loader.className = 'loader';
        loader.style.marginBottom = '10px';  // Adjust margin for spacing
        assistantMessageElement.appendChild(loader);
        chatContainerRef.current.appendChild(assistantMessageElement);

        setIsLoading(true); // Set loading to true to disable input and button



        // Prepare request URL
        const requestUrl = threadId
            ? `https://fileserver.teachmatter.com:3000/getFiles?thread_id=${threadId}&message=${encodeURIComponent(messageInput)}`
            : `https://fileserver.teachmatter.com:3000/getFiles?message=${encodeURIComponent(messageToSend)}`;

        console.log(`Making request to: ${requestUrl}`);

        // Make the request with XMLHttpRequest to handle the stream
        const xhr = new XMLHttpRequest();
        xhr.open('GET', requestUrl, true);
        xhr.setRequestHeader('Accept', 'text/event-stream');

        let previousResponseLength = 0;

        xhr.onprogress = () => {
            const newText = xhr.responseText.substring(previousResponseLength);
            previousResponseLength = xhr.responseText.length;

            console.log(`Received chunk: "${newText}"`);
            const lastAssistantMessage = chatContainerRef.current.lastChild;

            // Find the loader inside that message and hide it after the first chunk
            const loader = lastAssistantMessage.querySelector('.loader');
            if (loader) {
                loader.style.display = 'none';  // Hide the loader when the message starts
            }
            // Check if $$$ exists in the chunk, indicating the end of the message

            const endOfMessage = newText.includes('$$$');
            const cleanedText = newText.replace('$$$', '');

            try {
                const jsonStartIndex = cleanedText.indexOf('{');
                const jsonEndIndex = cleanedText.indexOf('}') + 1;
                let remainingText = cleanedText;

                // If JSON is present, parse it and extract thread_id
                if (jsonStartIndex >= 0 && jsonEndIndex > jsonStartIndex) {
                    const jsonResponse = cleanedText.slice(jsonStartIndex, jsonEndIndex);
                    const parsedResponse = JSON.parse(jsonResponse);

                    if (parsedResponse.thread_id) {
                        setThreadId(parsedResponse.thread_id);
                        console.log('Extracted thread_id:', parsedResponse.thread_id);
                    }

                    // Remove the JSON part from the remaining text
                    remainingText = cleanedText.slice(jsonEndIndex);
                }

                // Append new chunk to the assistant message in the DOM
                assistantMessageElement.textContent += remainingText;

                // If we detect the end of the message, stop updating and mark as complete
                if (endOfMessage) {
                    console.log('End of assistant message detected.');
                    setIsLoading(false); // Mark as completed
                    if (loaderRef.current) {
                        loaderRef.current.style.display = 'none';  // Hide loader as soon as the last chunk arrives
                    }
                    inputRef.current.focus();  // Focus back on the input field

                    const pasteButton = document.createElement('button');
                    pasteButton.innerHTML = '📋';  // Simple clipboard icon
                    pasteButton.style.background = 'none';
                    pasteButton.style.border = 'none';
                    pasteButton.style.cursor = 'pointer';
                    pasteButton.style.fontSize = '18px';
                    pasteButton.style.marginLeft = '10px'; // Adjust for spacing

                    // Add custom tooltip
                    createTooltip(pasteButton, 'Paste to whiteboard');

                    // Handle paste functionality
                    pasteButton.addEventListener('click', () => handlePasteToWhiteboard(assistantMessageElement.textContent));

                    // Append button next to the message
                    assistantMessageElement.appendChild(pasteButton);
                }

            } catch (e) {
                console.error('Failed to parse JSON:', e.message);
                assistantMessageElement.textContent += cleanedText; // Append as plain text if JSON parsing fails
            }

            scrollToBottom(); // Ensure the scroll is at the bottom after each chunk
        };

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status !== 200) {
                    console.log('Error: Unable to get response from server.');
                    assistantMessageElement.textContent += 'Error: Unable to get response from server.';
                }

                // First, ensure that isLoading is set to false to enable the input
                setIsLoading(false);
                if (loaderRef.current) {
                    loaderRef.current.style.display = 'none';  // Hide loader as soon as the first chunk arrives
                }
                // Then, check if the final message has been handled, if not, set focus
                if (!xhr.responseText.includes('$$$')) {
                    if (inputRef.current) {
                        inputRef.current.focus();
                    }
                }
            }
        };


        xhr.onerror = () => {
            console.error('Request failed. Unable to reach the server.');
            assistantMessageElement.textContent += 'Error: Request failed. Unable to reach the server.';
            setIsLoading(false);
        };

        xhr.send();
        setMessageInput('');
        setSelectedImage(null);
    };


    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImage(file);
        }
    };

    const handlePaste = (event) => {
        const items = event.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
                const file = items[i].getAsFile();
                if (file) {
                    setSelectedImage(file);
                }
            }
        }
    };

    const removeImage = () => {
        setSelectedImage(null);
    };

    const handleUploadFile = async (Data) => {
        let fileName = uuidv4()
        const imageData = new FormData();
        imageData.append('file', Data, fileName);
        let status, res;
        try {
            res = await uploadFile(accessToken, imageData);
            status = res.status
        }

        catch (err) {
            console.log(err);
            status = err.response.status;
        }
        // console.log(index)
        if (status === 200) {
            const fileUrl = UPLOAD_API_URL + 'uploads/' + clientID + '/' + fileName;
            return fileUrl
        }
        else {
            console.log(status)
            return ''
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                minHeight: '80vh',
                backgroundColor: '#f8f8f8',
                // padding: 1,
                // color: '#ffffff'
            }}
        >
            <div
                style={{
                    width: '100%',
                    // maxWidth: '800px',
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '15px',
                    height: selectedImage ? 'calc(55vh - 15px)' : 'calc(70vh - 15px)',
                    padding: '0 25px',
                    borderRadius: '10px 10px 0 0',
                    // maxHeight: '50vh',
                    // minHeight: '50vh',
                    color: '#000000',
                    overflowY: 'auto',
                    backgroundColor: '#ffffff',
                    // boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.3)',
                    marginBottom: '2.5px'
                }}
                ref={chatContainerRef}
            >
                {/* Existing messages and other content */}
            </div>

            {selectedImage && (
                <Box
                    sx={{

                        padding: '0 25px',

                        width: '100%',
                        // maxWidth: '800px'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: CHAT_MESSAGE_BOX_COLOR,
                            padding: '5px',
                            borderRadius: '10px 10px 0 0',
                            // marginBottom: '10px',
                            width: '100%',
                            position: 'relative',

                            // maxWidth: '800px'
                        }}
                    >
                        <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="Selected"
                            style={{ maxWidth: '15vh', maxHeight: '15vh', borderRadius: '10px' }}
                        />
                        <IconButton onClick={removeImage} sx={{ position: 'absolute', top: '5px', right: '5px' }}>
                            <CloseIcon sx={{ fontSize: '0.8rem' }} />
                        </IconButton>
                    </Box>
                </Box>
            )}

            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0 25px',
                    // backgroundColor: '#3a3a3a',
                    // borderTop: '1px solid #ddd',
                    borderRadius: '0 0 10px 10px',
                    width: '100%',
                    height: '10vh',
                    // maxWidth: '800px'
                }}
            >
                <input
                    type="text"
                    value={messageInput}
                    onChange={e => setMessageInput(e.target.value)}
                    placeholder="Enter your message here..."
                    onKeyDown={e => e.key === 'Enter' && !isLoading && handleSendMessage()}
                    onPaste={handlePaste}
                    disabled={isLoading}
                    style={{
                        width: '100%',
                        padding: '12px',
                        borderRadius: '30px',
                        backgroundColor: '#f8f8f8',
                        color: '#000000',
                        marginRight: '10px',
                        border: '1px solid grey',
                        outline: 'none',
                        fontSize: '16px',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)'
                    }}
                    ref={inputRef}
                    onFocus={() => {
                        isTextFocused.current = true;
                    }}
                    onBlur={() => {
                        isTextFocused.current = false;
                    }}
                />

                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                    id="image-upload"
                />
                <label htmlFor="image-upload">
                    <Button component="span" sx={{ marginRight: '10px' }} disabled={isLoading}>
                        <AttachFileIcon sx={{ color: '#000000', }} />
                    </Button>
                </label>

                <button
                    style={{
                        padding: '12px 24px',
                        borderRadius: '30px',
                        backgroundColor: THEME_COLOR,
                        color: 'white',
                        border: 'none',
                        fontSize: '16px',
                        cursor: isLoading || (!messageInput.trim() && !selectedImage) ? 'default' : 'pointer',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                        transition: 'background-color 0.3s ease',
                    }}
                    onClick={handleSendMessage}
                    disabled={isLoading || (!messageInput.trim() && !selectedImage)}
                >
                    Send
                </button>
            </Box>
        </Box>
    );
};

export default UploadContentAI;
