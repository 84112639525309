import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import { createTheme, ThemeProvider, Slider } from "@mui/material";
import PaletteIcon from '@mui/icons-material/Palette';
import ScreenCapture from '../screenCapture/screenCapture'
// import downloadItems from 'src/components/DownloadItems';
// import MenuBookIcon from '@mui/icons-material/MenuBook';
// import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
// import ShowChartIcon from '@mui/icons-material/ShowChart';
import LineWeightIcon from '@mui/icons-material/LineWeight';
// import BorderStyleIcon from '@mui/icons-material/BorderStyle';
import { TableRow, Table, TableBody, TableCell, Paper, Tooltip, Popover, FormControl, Divider, Drawer, DialogActions, Dialog, DialogTitle, DialogContent, Chip, OutlinedInput, Avatar, Card, CircularProgress } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import NearMeIcon from '@mui/icons-material/NearMe';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';
import DeleteIcon from "@mui/icons-material/Delete";
// import SaveIcon from "@mui/icons-material/Save";
import AddIcon from '@mui/icons-material/Add';
// import CopyIcon from "@mui/icons-material/FileCopy";
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import TitleIcon from '@mui/icons-material/Title';
import DownloadIcon from "@mui/icons-material/CloudDownload";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import CategoryIcon from '@mui/icons-material/Category';
import RemoveIcon from "@mui/icons-material/Remove";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import Typography from "@mui/material/Typography/Typography";
import IconButton from "@mui/material/IconButton";
import { Grid, Box } from "@mui/material";
import CreateIcon from '@mui/icons-material/Create';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Crop169Icon from '@mui/icons-material/Crop169';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import PanToolIcon from '@mui/icons-material/PanTool';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PermCameraMicIcon from '@mui/icons-material/PermCameraMic';
import { TwitterPicker } from "react-color";
// import awsmobile from 'src/aws-exports';
import { pdfjs, } from "react-pdf";
import { v4 as uuidv4 } from 'uuid';
// import { Storage } from 'aws-amplify';
import RecordingButtonExternal from '../classroom2/RecordingButton';
import AppsIcon from '@mui/icons-material/Apps';
import CloseIcon from "@mui/icons-material/Close";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import graph1 from './images/graph1.png';
import graph2 from './images/graph2.png';
import fraction1by2 from './images/fraction1by2.png';
import fractionWhole from './images/fractionWhole.png';
import fast from './images/fast.svg';
import protractor2 from './images/protractor2.png';
import protractor from './images/protractor.png';
import cube3d from './images/cube3d.png';
import ruler from './images/ruler.png';
import { convertFileToImage, getUserFiles, sendChatMessage, sendMessage, shareFile, updateSessionState, updateSlateTitle, updateSlateVisibility, uploadFile, uploadWhiteboardFiles } from "./src/service/slateService";
import downloadItems from "../components/DownloadItems";
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import DoneIcon from '@mui/icons-material/Done';
import ShareIcon from '@mui/icons-material/Share';
import { FRONTEND_URL, MAX_ZOOM, MIN_ZOOM, STREAM_FILE_SERVER, THEME_COLOR, THEME_HOVER_COLOR, UPLOAD_API_URL, WHITEBOARD_ICON_COLOR, WHITEBOARD_TOOLBAR_COLOR } from "./src/config/constants";
// Storage.configure(awsmobile);
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import TeacherTour from "../components/TeacherTour";
import StudentTour from "../components/StudentTour";

import PublicIcon from '@mui/icons-material/Public';
import LockIcon from '@mui/icons-material/Lock';
// import ShareSlate from "./src/components/EditSlate/ShareSlate";
import { capitalizeFirstAlphabet, findObjectById } from "../components/helperFunctions";
import html2canvas from "html2canvas";
import EditSlate from "./src/components/EditSlate/EditSlate";
import AssignmentIcon from '@mui/icons-material/Assignment';
import { getCookie, getProfileList, getYoutubeLinkId, setCookie } from "../components/utils";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { faEraser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShortcutGuide from "../classroom2/ShortcutGuide";
import HexagonIcon from '@mui/icons-material/Hexagon';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { v4 as uuid4 } from 'uuid';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CalculateIcon from '@mui/icons-material/Calculate';
import { uuid } from "uuidv4";
import axios from 'axios';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import EditOffIcon from '@mui/icons-material/EditOff';
import { isToolbarEnabledAtom } from "../components/jotai/atom/isToolbarEnabledAtom";
import { useAtom, useAtomValue } from "jotai";
import DisplayAvatars from "./src/components/DisplayAvatars/DisplayAvatars";
import CustomLoader from '../components/CustomLoader/CustomLoader';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import { isPanEnabledAtom } from "../components/jotai/atom/isPanEnabledAtom";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PermissionDrawerButton from "./src/components/PermissionDrawer/PermissionDrawerButton";
import SearchContent from "../pages/SearchContent";
import Chat from "../Chat/Chat";
import EndSessionStepperForm from "../classroom2/EndSessionStepperForm";
import Arrow from './src/tools/Arrow';
import Tool from './src//tools';
import DefaultTool from './src/tools/defaul-tool';
import Select from './src/tools/Select';
import Pencil from './src/tools/Pencil';
import Line from './src/tools/Line';
import Rectangle from './src/tools/Rectangle';
import RectangleLabel from './src/tools/Rectangle/rectangle-label';
import Circle from './src/tools/Circle';
import Pan from './src/tools/Pan';
import Eraser from './src/tools/Eraser';
import Highlighter from './src/tools/Highlighter';
import DashedLine from './src/tools/DashedLine'
import UndoRedoButton from "./UndoRedoButton";
import { slateDataReceivedAtom } from "../components/jotai/atom/slateDataReceivedAtom";
import TechSupport from "./TechSupport";
import UploadContentAI from "./UploadContentAI";
import ChatIcon from '@mui/icons-material/Chat';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const styles = {
    root: {
        padding: "3px",
        display: "flex",
        flexWrap: "wrap",
        margin: "10px 10px 5px 10px",
        justifyContent: "space-around",
    },
    gridList: {
        width: "100%",
        marginBottom: "24px",
    },
    gridTile: {
        backgroundColor: "#fcfcfc",
    },
    appBar: {
        // backgroundColor: "#311b92",
        // minHeight:"70px",

    },
    radioButton: {
        marginTop: "3px",
        marginBottom: "3px",
    },
    separator: {
        height: "42px",
        backgroundColor: "white",
    },
    iconButton: {
        // fill: "black",
        // width: "42px",
        // height: "42px",
    },
    dropArea: {
        width: "100%",
        height: "64px",
        border: "2px dashed rgb(102, 102, 102)",
        borderStyle: "dashed",
        borderRadius: "5px",
        textAlign: "center",
        paddingTop: "20px",
    },
    activeStyle: {
        borderStyle: "solid",
        backgroundColor: "#eee",
    },
    rejectStyle: {
        borderStyle: "solid",
        backgroundColor: "#ffdddd",
    },
    card: {
        margin: "5px",
        padding: "5px",
        border: "0.1px solid #9fa3ab"
    },
    selected: {
        color: THEME_COLOR
        // backgroundColor: "#532efe",
    },
    expandedCard: {
        position: "relative",
        bottom: "140px",
        padding: "5px",
        margin: "10px",
    }
};

const SmileyCollection = [
    '👍',
    '😊',
    '😂',
    '🤩',
    '😭'
]

const colors = ['#F8F8F8', '#F3EEEA', '#000000', '#4D4D4D', '#FFFFFF', '#F44E3B', '#FFD580', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF'];

const lineColors = ['#000000', '#4D4D4D', '#999999', '#FFFFFF', '#C91500', '#FE9200', '#FFFA00', '#0F531A', '#BDFF00', '#00CCC7', '#00B2F7', '#2100F1', '#FA00FF'];
const highlighterColors = ['#424242', '#BDBDBD', '#FFD1CC', '#FFDBAA', '#FFFD9A', '#B2EFBC', '#D4E4A9', '#C0FFFD', '#B2E0F1', '#D0C8FF', '#EEC3EF']

function CustomToolbar(props, ref) {
    const theme1 = useTheme();
    let isMobile = useMediaQuery(theme1.breakpoints.down(500));
    // if (window.innerHeight && window.innerHeight <= 480) {
    //     isMobile = true;
    // }
    const fullScreen = useMediaQuery(theme1.breakpoints.down('xs'));
    const [iconColor, setIconColor] = useState(WHITEBOARD_ICON_COLOR)
    const [openBackgroundColorPicker, setOpenBackgroundColorPicker] = useState(false);
    const [openLineWidthPicker, setOpenLineWidthPicker] = useState(false);
    const [openLineColorPicker, setOpenLineColorPicker] = useState(false);
    const [openShapes, setOpenShapes] = useState(false);
    const [openApps, setOpenApps] = useState(false);
    const [openCopiedToClipboard, setOpenCopiedToClipboard] = useState(false);
    const [openAddedToNotes, setOpenAddedToNotes] = useState(false);
    // const [pdfDirectoryArray, setPdfDirectoryArray] = useState([]);
    const [directoryBox, setDirectoryBox] = useState(false);
    const theme = createTheme();
    const [uploadedFilesArray, setUploadedFilesArray] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [filteredUploadFiles, setFilteredUploadFiles] = useState([]);
    const [fileType, setFileType] = useState('Other');
    const [openShareNotification, setOpenShareNotification] = useState(false);
    const [openSendMessageNotification, setOpenSendMessageNotification] = useState(false);
    const [openSmileyBox, setOpenSmileyBox] = useState(false)
    const [openConfirmationForUpload, setOpenConfirmationForUpload] = useState(false)
    const [uploadedFileUrl, setUploadedFileUrl] = useState('')
    const [uploadedFileName, setUploadedFileName] = useState('')

    const [slateVisibility, setSlateVisibility] = useState('')
    const [openVisibilityBox, setOpenVisibilityBox] = useState(false)
    const [openDownloadNotesBox, setOpenDownloadNotesBox] = useState(false)
    const [openScreenshotToNotesBox, setOpenScreenshotToNotesBox] = useState(false)
    const [openDownloadWhiteboardBox, setOpenDownloadWhiteboardBox] = useState(false)
    const [openInternetBox, setOpenInternetBox] = useState(false)
    const [openEmptyNotesNotification, setOpenEmptyNotesNotification] = useState(false);
    // const [openSlatePad, setOpenSlatePad] = useState(false)
    const [showInputElement, setShowInputElement] = useState(false)
    const [slateTitle, setSlateTitle] = useState('')
    const [slateTitleChanged, setSlateTitleChanged] = useState(false)
    const [showTopBar, setShowTopBar] = useState(true)
    const [profileUrlList, setProfileUrlList] = useState([])
    // const [openGoToBox, setOpenGoToBox] = useState(false)
    const [openBookmarkBox, setOpenBookmarkBox] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [bookmarkPageNumber, setBookmarkPageNumber] = useState('')
    const [bookmarksList, setBookmarksList] = useState([])
    const [clearAllBox, setClearAllBox] = useState(false)
    const [openPdfBox, setOpenPdfBox] = useState(false)
    const [pdfImagesArray, setPdfImagesArray] = useState([])
    const [pdfToPasteArray, setPdfToPasteArray] = useState([])
    const [openAsklink, setOpenAsklink] = useState(false);
    const [openAskPdflink, setOpenAskPdflink] = useState(false);
    const [openYoutubelink, setOpenYoutubelink] = useState(false);
    const [pdfLink, setPdfLink] = useState('')
    const [youtubeLink, setYoutubeLink] = useState('')
    const [iframeLink, setIframeLink] = useState('')
    const [openCalculator, setOpenCalculator] = useState(false)
    const [pdfLoader, setPdfLoader] = useState(false)
    const [fileUploadName, setFileUploadName] = useState('')
    const [toolBarButtonState, setToolBarButtonState] = useState(true)
    const [togglePanButtonState, setTogglePanButtonState] = useState(false)
    const [audioVideoState, setAudioVideoState] = useState(true)
    const [openZoomBox, setOpenZoomBox] = useState(false)
    const [zoomSliderLevel, setZoomSliderLevel] = useState(100)
    const [openToggledPan, setOpenToggledPan] = useState(false)
    const [openToggledToolbar, setOpenToggledToolbar] = useState(false)
    const [openHighlighterColorPicker, setOpenHighlighterColorPicker] = useState(false)
    const [contentBox, setContentBox] = useState(false)
    // const [openClickPrompt, setOpenClickPrompt] = useState(false)
    const [openDownloadWhiteboardNotification, setOpenDownloadWhiteboardNotification] = useState(false)
    // const [openShareFileLink, setOpenShareFileLink] = useState(false);
    // const [fileLink, setFileLink] = useState('')
    const [recenterBox, setRecenterBox] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [showBottomToolbar, setShowBottomToolbar] = useState(isMobile ? false : true)
    const [showLeftToolbar, setShowLeftToolbar] = useState(isMobile ? false : true)

    const [totalPagesToUpload, setTotalPagesToUpload] = useState(0);
    const [pagesUploaded, setPagesUploaded] = useState(0);
    const [displayToolbarToggle, setDisplayToolbarToggle] = useState(false)
    const [lineWidth, setLineWidth] = useState(3);
    const [lineColor, setLineColor] = useState(props.isTeacher ? '#C91500' : "#00B2F7");
    const [highlighterColor, setHighlighterColor] = useState(props.isTeacher ? '#FFD1CC' : "#B2E0F1");
    const [highlighterWidth, setHighlighterWidth] = useState(9);
    const [pointerType, setPointerType] = useState('pencil')
    const [toolName, setToolName] = useState(Boolean(props.isPlayer) ? "DefaultTool" : (Boolean(props.isViewer) ? "Pan" : 'Pencil'));
    const [fillColor, setFillColor] = useState("transparent");
    const [openGPT, setOpenGPT] = useState(false);
    const [clippedFileData, setClippedFileData] = useState('')

    const pdfWindowState = useRef(null)

    const [isToolbarEnabled] = useAtom(isToolbarEnabledAtom)
    const [isPanEnabled, setIsPanEnabled] = useAtom(isPanEnabledAtom)
    const [endSessionBox, setEndSessionBox] = useState(false);
    const [sessionReport, setSessionReport] = useState(false)
    const [closeSessionBox, setCloseSessionBox] = useState(false);
    const [downloadingProgress, setDownloadingProgress] = useState(0);
    const [totalNumberOfPages, setTotalNumberOfPages] = useState(1);
    const [canvasZoomLevel, setCanvasZoomLevel] = useState(1);
    const [backgroundColor, setBackgroundColor] = useState("#F8F8F8");
    const [patternColor, setPatternColor] = useState('#9F9EB2');
    const [backgroundType, setBackgroundType] = useState('None');
    const slateDataReceived = useAtomValue(slateDataReceivedAtom);



    const _tools = useRef(null);

    useImperativeHandle(ref, () => ({
        handleIsPdf,
        handlePptxDocs,
        handleChangeUploading,
        _selectTool,
        setLineColor,
        setHighlighterColor,
        setLineWidth,
        setHighlighterWidth,
        setTotalNumberOfPages,
        setPageNumber,
        setCanvasZoomLevel,
        setBackgroundColor,
        setPatternColor,
        setBackgroundType,
        setClippedFileData,
        setOpenGPT
    }));

    const _initTools = (fabricCanvas) => {
        _tools.current = {}
        _tools.current.Select = new Select(fabricCanvas);
        _tools.current.Pencil = new Pencil(fabricCanvas);
        _tools.current.Line = new Line(fabricCanvas);
        _tools.current.Arrow = new Arrow(fabricCanvas);
        _tools.current.DashedLine = new DashedLine(fabricCanvas);
        _tools.current.Rectangle = new Rectangle(fabricCanvas);
        _tools.current.RectangleLabel = new RectangleLabel(fabricCanvas);
        _tools.current.Circle = new Circle(fabricCanvas);
        _tools.current.Pan = new Pan(fabricCanvas);
        _tools.current.Highlighter = new Highlighter(fabricCanvas);
        _tools.current.DefaultTool = new DefaultTool(fabricCanvas);
        _tools.current.Eraser = new Eraser(fabricCanvas);
    };



    const _selectTool = (event) => {
        // console.log("onchange select tool", event.target.value)
        const value = event.target.value;
        setToolName(value);
        // console.log(toolName)
    };

    const handleLineWidth = (val) => {
        // console.log('we are here ', val)
        setLineWidth(val)

    }
    const handleLineColor = (val) => {
        setLineColor(val)
    }

    const handleHighlighterColor = (val) => {
        setHighlighterColor(val)
    }

    const handleHighlighterWidth = (val) => {
        // console.log('we are here ', val)
        setHighlighterWidth(val)
    }


    useEffect(() => {
        if (props.isCanvasInitialized) {
            if (props._sketch.current._fc.current) {
                _initTools(props._sketch.current._fc.current);
                let selectedTool = _tools.current[toolName];
                // console.log("the selected tool is" + JSON.stringify(selectedTool));
                // console.log("the selected tool is" + _fc.current);
                // console.log("passiing props" + JSON.stringify(props))
                if (selectedTool) {
                    callConfigureCanvas(selectedTool)
                }
            }
        }

    }, [props.isCanvasInitialized])

    useEffect(() => {


        setTimeout(async () => {
            // console.log(props.slateRef, 'slatey')
            if (props.slateRef && props.slateRef.current) {
                // console.log('slateyyy')
                setSlateVisibility(props.slateRef.current.visibility)
                setSlateTitle(props.slateRef.current.title)
                let tempProfileList = await getProfileList(props.slateRef, props.accessToken)
                setProfileUrlList(tempProfileList)
                // console.log(tempProfileList)
            }
        }, [5000])
        initializeBookmarkList()

    }, [])

    useEffect(() => {
        props._selectedTool.current = _tools.current[toolName];
        //Bring the cursor back to default if it is changed by a tool
        props.currentTool.current = toolName;
        props._sketch.current._fc.current.defaultCursor = 'default';
        if (props._selectedTool.current) {
            callConfigureCanvas(props._selectedTool.current)
        }
    }, [toolName])

    useEffect(() => {
        props.lineColorRef.current = lineColor;
        props.lineWidthRef.current = lineWidth;
        props.highlighterWidthRef.current = highlighterWidth;
        props.highlighterColorRef.current = highlighterColor;
        if (props._selectedTool.current) {
            callConfigureCanvas(props._selectedTool.current)
        }
    }, [lineColor, lineWidth, highlighterWidth, highlighterColor, pointerType])



    useEffect(() => {
        if (toolName === 'Highlighter') {
            if (props._selectedTool.current) {
                callConfigureCanvas(props._selectedTool.current)
            }
        }
    }, [toolName])

    const callConfigureCanvas = (tool) => {
        // console.log(lineColor, lineWidth, highlighterWidth, highlighterColor, pointerType)
        const toolProps = {
            'lineColor': lineColor,
            'lineWidth': lineWidth,
            'highlighterColor': highlighterColor,
            'highlighterWidth': highlighterWidth,
            'fillColor': fillColor,
            'pointerType': pointerType
        }
        // console.log(toolProps)
        tool.configureCanvas(toolProps)
    }



    useEffect(() => {
        // console.log('list jupdated')
        // console.log(profileUrlList)
    }, [profileUrlList])

    useEffect(() => {
        // console.log('list jupdated')
        // console.log(profileUrlList)
        setZoomSliderLevel(canvasZoomLevel)
    }, [canvasZoomLevel])

    useEffect(() => {

    }, [document.fullscreenElement])

    useEffect(() => {
        if (isMobile) {
            setDisplayToolbarToggle(true)
            if (document.getElementById('slate-pad-button')) {
                console.log(document.getElementById('slate-pad-button').style.display)
                document.getElementById('slate-pad-button').style.display = 'none'
            }
            if (document.getElementById('periodic-table-button')) {
                document.getElementById('periodic-table-button').style.display = 'none'
            }
            if (document.getElementById('timer-button')) {
                document.getElementById('timer-button').style.display = 'none'
            }
            if (document.getElementById('desmos-button')) {
                document.getElementById('desmos-button').style.display = 'none'
            }
            if (document.getElementById('apps-left-button')) {
                document.getElementById('apps-left-button').style.display = 'none'
            }
            if (document.getElementById('content-library-button')) {
                document.getElementById('content-library-button').style.display = 'none'
            }
            if (document.getElementById('screenshot-button')) {
                document.getElementById('screenshot-button').style.display = 'none'
            }
            if (document.getElementById('left-toolbar')) {
                document.getElementById('left-toolbar').style.display = 'none'
            }
        }

        if (window.innerHeight && window.innerHeight <= 480) {
            if (document.getElementById('timer-button')) {
                document.getElementById('timer-button').style.display = 'none'
            }
            if (document.getElementById('content-library-button')) {
                document.getElementById('content-library-button').style.display = 'none'
            }
            if (document.getElementById('periodic-table-button')) {
                document.getElementById('periodic-table-button').style.display = 'none'
            }
            if (document.getElementById('slate-pad-button')) {
                console.log(document.getElementById('slate-pad-button').style.display)
                document.getElementById('slate-pad-button').style.display = 'none'
            }
        }

        if (window.innerHeight && window.innerHeight <= 480 && window.innerWidth && window.innerWidth >= 700) {
            setDisplayToolbarToggle(true)
            setShowBottomToolbar(false)
        }
    }, [])

    const initializeBookmarkList = async () => {
        const tempBookmarkListJson = await getCookie('PAGE_NUMBER_BOOKMARK')
        if (tempBookmarkListJson) {
            // console.log('found')
            const tempBookmarkList = JSON.parse(tempBookmarkListJson)
            // console.log('found',tempBookmarkListJson)
            setBookmarksList(tempBookmarkList)
        }
    }

    function sendSmiley(userID, smiley, name) {
        const smileyID = userID + '-smiley';
        props.sendSmileyEventViaWebSocket({
            "smileyTileID": smileyID,
            'sentBy': name,
            'smiley': smiley
        })
        document.getElementById(smileyID).innerHTML = smiley;
        document.getElementById(smileyID).style.display = 'flex';
        setTimeout(() =>
            document.getElementById(smileyID).style.display = 'none'
            , 2000)
            ;
    }

    const addEmailToArray = async (array, userID) => {
        let newArray = [];
        for (let i = 0; i < array.length; ++i) {
            if (array[i].user_id !== userID) {
                newArray.push(array[i].email)
            }
        }
        return newArray;
    }

    const handleShareFile = async (accessToken, fileLink, uploadFileName) => {
        let emailIdArray = []
        // console.log(props.slateRef.current)
        let emailIdFromHosts = await addEmailToArray(props.slateRef.current.hostDetails, props.clientID)
        let emailIdFromparticipants = await addEmailToArray(props.slateRef.current.participantDetails, props.clientID)
        emailIdArray = [...emailIdFromHosts, ...emailIdFromparticipants]
        // console.log(emailIdArray)
        const ShareData = {
            "emailIds": emailIdArray,
            "fileLink": fileLink
        }
        let status, res;
        try {
            res = await shareFile(accessToken, ShareData);
            status = res.status
        }

        catch (err) {
            console.log(err);
            status = err.response.status;
        }

        if (status === 200) {
            // console.log(props.userName)
            const fileSharedData = {
                "fileLink": fileLink,
                "sharedBy": props.clientID,
                "fileName": uploadFileName
            }
            props.sendFileSharedEventViaWebSocket(fileSharedData)
            setOpenShareNotification(true)

        }
        else {
        }
    }



    const checkKeyPress = (e) => {
        const { key, keyCode } = e;
        // console.log(key, keyCode);
        if (keyCode === 13) {
            handleBlur()
        }
    };

    const handleBlur = () => {
        props.isTextFocused.current = false;
        if (slateTitleChanged) {
            if (slateTitle.length !== 0) {
                setShowInputElement(false)
                // console.log(slateTitle)
                changeSlateTitle(slateTitle)
            }
        }
        else {
            if (slateTitle.length !== 0) {
                setShowInputElement(false)
            }
        }
        setSlateTitleChanged(false)
    }

    const changeSlateTitle = async (tempSlateTitle) => {
        // console.log(tempSlateTitle)
        const SlateData = {
            "title": tempSlateTitle,
            "slateId": props.slateRef.current.id
        }
        // console.log(SlateData)
        // console.log(accessToken)
        let status, res;
        try {
            res = await updateSlateTitle(props.accessToken, SlateData);
            status = res.status
        }

        catch (err) {
            console.log(err);
            status = err.response.status;
        }
        // console.log(index)
        if (status === 200) {
            //success
        }
        else if (status === 401) {
            alert("You are not authorized to edit slate")
        }
        else {
            console.log(status)
        }
    }


    const findSearchTextInFiles = (text) => {
        const currentListArray = JSON.parse(JSON.stringify(uploadedFilesArray))
        const filteredArray = [];
        for (let i = 0; i < currentListArray.length; ++i) {
            if (currentListArray[i].includes(text) || (currentListArray[i].toLowerCase()).includes(text.toLowerCase()) || (splitFileName(currentListArray[i]).includes(text)) || (splitFileName(currentListArray[i]).toLowerCase()).includes(text.toLowerCase())) {

                filteredArray.push(currentListArray[i]);

            }
        }
        // console.log('filtered array', filteredArray)
        setFilteredUploadFiles(filteredArray);
    }

    const convertTimeToLocal = (time) => {
        var date = new Date(time);
        // console.log(time)
        return date.toLocaleString()

    }

    const isDate = function (date) {
        return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
    }

    const splitFileName = (name) => {
        let nameArray = name.split('_')
        let newName = '';
        for (let i = 0; i < nameArray.length; ++i) {
            if (i !== nameArray.length - 2) {
                if (i === nameArray.length - 1) {
                    // newName = newName + 'data'
                }
                else {

                    if (isDate(nameArray[i]) && !(/^\d+$/.test(nameArray[i]))) {
                        // console.log(nameArray[i], ' is date')
                        newName = newName + convertTimeToLocal(nameArray[i]) + '_'
                    }
                    else {
                        // console.log(nameArray[i], ' is not date')
                        newName = newName + nameArray[i] + '_'
                    }

                }

            }
        }
        return newName.substring(0, newName.length - 1);
    }

    const handleUpload = async (event, accessToken, handleChangeLoading) => {
        handleChangeLoading(true)
        const file = event.target.files[0];
        const filePathArray = event.target.value.split("\\");
        // console.log("file path array " + filePathArray)
        const uploadFileName = filePathArray[filePathArray.length - 1];
        // console.log("file name " + uploadFileName)
        let uploadFileUrl = UPLOAD_API_URL + 'uploads/' + props.clientID + '/' + uploadFileName;
        uploadFileUrl = uploadFileUrl.replace(/ /g, '%20')
        // console.log(uploadFileUrl)
        const imageData = new FormData();
        imageData.append('file', file);
        let status, res;
        try {
            res = await uploadFile(accessToken, imageData);
            status = res.status
        }

        catch (err) {
            console.log(err);
            status = err.response.status;
        }
        // console.log(index)
        if (status === 200) {
            handleShareFile(accessToken, uploadFileUrl, uploadFileName);
        }
        else {
            console.log(status)
        }
        handleChangeLoading(false);
        document.getElementById('file-to-upload-and-share').value = null;
    }



    const changeVisibilityOfSlate = async (visibility, accessToken) => {
        const SlateData = {
            "visibility": visibility,
            "slateId": props.slateRef.current.id
        }
        // console.log(SlateData)
        // console.log(accessToken)
        let status, res;
        try {
            res = await updateSlateVisibility(accessToken, SlateData);
            status = res.status
        }

        catch (err) {
            console.log(err);
            status = err.response.status;
        }
        // console.log(index)
        if (status === 200) {
            setSlateVisibility(visibility)
        }
        else if (status === 401) {
            alert("You are not authorized to edit slate")
        }
        else {
            console.log(status)
        }

    }

    const handleCloseShareNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenShareNotification(false);
    };

    const handleCloseSendMessageNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSendMessageNotification(false);
    };

    const handleCloseDownloadWhiteboardNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenDownloadWhiteboardNotification(false);
    }

    const handleCloseEmptyNotesNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenEmptyNotesNotification(false);
    };


    function removeDuplicates(arr) {
        return arr.filter((element, index, array) => array.findIndex(subElement => subElement.email === element.email) === index);
    }

    // const handleChangeInPdfArray = (pdfObj) => {
    //     setPdfImagesArray(prevValue => {
    //         return [...prevValue, pdfObj]
    //     })
    // }

    // const handleOpenPdfBox = (value) => {
    //     setOpenPdfBox(value)
    // }

    const handleClosePdfWindow = () => {
        setOpenPdfBox(false)
        pdfWindowState.current = false
        setPdfImagesArray([])
        setPdfToPasteArray([])
        window.whiteboardScrollDisable = false
    }

    const handleChangeUploading = (value) => {
        setIsUploading(value)
    }

    const handleTotalPagesToUpload = (value) => {
        setTotalPagesToUpload(value)
    }

    const handlePagesUploaded = (value) => {
        setPagesUploaded(value)
    }


    const handleIsPdf = async (e, fileUrl) => {
        setPdfImagesArray([])
        setOpenPdfBox(true)
        pdfWindowState.current = true
        const pdfImages = await convertPdfToBox(
            e, fileUrl
        )
        // console.log(pdfImages)
        setPdfImagesArray(pdfImages)
        window.whiteboardScrollDisable = true
        if (pdfImages.length === 0) {
            handleClosePdfWindow()
        }
        document.getElementById('file-to-upload').value = null;
    }

    const convertPdfToBox = async (event, fileUrl) => {
        setPdfLoader(0)
        var data
        if (fileUrl) {
            try {
                await axios({
                    method: 'GET',
                    url: fileUrl,
                    responseType: 'arraybuffer',
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': '*',
                        'Access-Control-Allow-Credentials': 'true'
                    }
                })
                    .then(async (response) => {
                        // console.log(response.data)
                        data = response.data
                    })
            }
            catch (err) {
                alert("File could not be uploaded")
                return []
            }
        }
        else {
            const file = event.target.files[0];
            // console.log('uploaded file', file)
            if (file && file.size > 100000000) {
                // handleChangeLoading(false);
                alert("file size limit exceeded!(file size less than 100MB is allowed)");
                document.getElementById('file-to-upload').value = null;
                return []
            }
            data = await readFileData(file);
        }

        // console.log('uploaded file', data)
        const pdf = await pdfjs.getDocument(data).promise;
        // console.log(pdf)
        const canvas = document.createElement("canvas");
        // const fileLink = "https://" + awsmobile.aws_user_files_s3_bucket + ".s3.ap-south-1.amazonaws.com/public/";
        // const filePathArray = event.target.value.split("\\");
        const uploadFileName = uuidv4();
        setFileUploadName(uploadFileName)
        let imagesArray = []
        for (let i = 0; i < pdf.numPages; i++) {
            setPdfLoader(parseInt(((i + 1) / pdf.numPages) * 100))
            const page = await pdf.getPage(i + 1);
            const viewport = page.getViewport({ scale: 2 });
            const context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            await page.render({ canvasContext: context, viewport: viewport }).promise;
            const daturl = canvas.toDataURL("image/png")
            const blob = await fetch(daturl).then(res => res.blob())
            // console.log(blob)
            const imageBlob = await blobToBase64(blob)
            // console.log(imageBlob);
            imagesArray.push({
                'src': imageBlob,
                'pageIndex': i,
                'type': 'pdf'
            })
        }
        // console.log(imagesArray)
        canvas.remove();
        document.getElementById('file-to-upload').value = null;
        // console.log(pdfWindowState.current)
        if (!pdfWindowState.current) {
            return []
        } else {
            return imagesArray
        }

    }

    const readFileData = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                resolve(e.target.result);
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsDataURL(file);
        });
    };


    const blobToBase64 = async (blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    };

    function b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    const handleScreenCapture = (screenCapture, width, height) => {
        setOpenScreenshotToNotesBox(false)
        // saveClippedImage('notes/' + props.clientID + '/', screenCapture, width, height, props.accessToken);
        console.log('yo')
        setOpenGPT(true)
        let block = screenCapture.split(";");
        // Get the content type
        let contentType = block[0].split(":")[1];
        // get the real base64 content of the file
        let realData = block[1].split(",")[1];// In this case "iVBORw0KGg...."

        // Convert to blob
        let blob = b64toBlob(realData, contentType);
        setClippedFileData(blob)
    };

    const handlePptxDocs = async (event, fileType, fileUrl) => {
        // let prefix = uuidv4()
        let status, res;
        let uploadFolder = uuidv4();
        setFileUploadName(uploadFolder)
        let file;
        setIsUploading(true);
        if (fileUrl) {
            try {
                await axios({
                    method: 'GET',
                    url: fileUrl,
                    responseType: 'blob',
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Headers': '*',
                        'Access-Control-Allow-Credentials': 'true'
                    }
                })
                    .then(async (response) => {
                        // console.log(response.data)
                        file = response.data
                    })
            }
            catch (err) {
                alert("File could not be uploaded")
                setIsUploading(false);
                return;
            }

        }
        else {
            file = event.target.files[0];
        }
        const pptOrDocFile = new FormData();
        pptOrDocFile.append('file', file, uploadFolder + '.' + fileType);
        //storing to whiteboardID
        try {
            res = await uploadWhiteboardFiles(props.accessToken, props.whiteBoardID, fileType + '/' + uploadFolder, pptOrDocFile);
            status = res.status
        }

        catch (err) {
            console.log(err);
            setIsUploading(false);
            // status = err.response.status;
        }
        // console.log(index)
        if (status === 200) {
            // alert('file uploaded')
            let pptFileLink = UPLOAD_API_URL + "uploads/whiteBoardStorage/" + props.whiteBoardID + '/' + fileType + '/' + uploadFolder + '/' + uploadFolder + '.' + fileType
            // console.log(pptFileLink)
            let fileObject = {
                "fileName": uploadFolder + '.' + fileType,
                "sessionId": props.whiteBoardID,
                "prefix": uploadFolder,
                "subFolder": fileType

            }
            setIsUploading(false);
            setPdfImagesArray([])
            setPdfToPasteArray([])
            setOpenPdfBox(true)
            setPdfLoader(0)
            pdfWindowState.current = true
            let result, status1
            try {
                result = await convertFileToImage(props.accessToken, fileObject);
                status1 = res.status
            }

            catch (err) {
                console.log(err);
                status1 = err.response.status;
            }
            if (status1 === 200) {
                // console.log(result)
                let imagesArray = result.data.convertedFileList
                let imagesDataArray = []
                for (let i = 1; i <= imagesArray.length; ++i) {
                    setPdfLoader(parseInt(((i + 1) / imagesArray.length) * 100))
                    let storageFileLink = UPLOAD_API_URL + "uploads/whiteBoardStorage/" + props.whiteBoardID + '/' + fileType + '/' + uploadFolder + '/' + uploadFolder + '.' + i + '.png'
                    imagesDataArray.push({
                        'src': storageFileLink,
                        'pageIndex': i,
                    })
                }
                // console.log(imagesDataArray)
                setPdfImagesArray(imagesDataArray)
                window.whiteboardScrollDisable = true
                if (imagesDataArray.length === 0) {
                    handleClosePdfWindow()
                }
            }
            else {
                // console.log(status1)
                handleClosePdfWindow()
            }

        }
        else {
            console.log(status)
        }
        setIsUploading(false);
        document.getElementById('file-to-upload').value = null;
    }

    function openFullscreen() {
        let elem = document.documentElement;
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
    }

    function closeFullscreen() {
        // let elem = document.documentElement;
        if (document.fullscreenElement !== null) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        }

    }

    let endSessionButton = <>
        <Tooltip title='End Class'>
            <IconButton
                sx={{
                    color: '#ffffff',
                    marginLeft: '20px',
                    borderRadius: '5px',
                    display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' },
                    border: '1.5px solid #D3D3D3',
                    marginTop: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                    marginBottom: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                    backgroundColor: THEME_COLOR,
                    // width: { xs: '20px', sm: '40px', md: '40px', lg: '60px' },
                    "&:hover": { backgroundColor: THEME_COLOR }
                }}
                onClick={(event) => {
                    setEndSessionBox(true)
                }
                }
            >
                <Typography sx={{ fontSize: '0.8rem', fontWeight: 550 }}>
                    End Class
                </Typography>


            </IconButton>
        </Tooltip>
        <Dialog
            open={endSessionBox}
            onClose={() => {
                setEndSessionBox(false)
            }}
        >
            <DialogTitle sx={{ justifyContent: 'center', textAlign: 'center', color: '#B33A3A' }} >
                Warning!
            </DialogTitle>
            <DialogContent>
                <Typography sx={{ fontSize: "1rem" }}>Are you sure you want to end the session?</Typography>
            </DialogContent>
            <DialogActions>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Button
                        sx={{ width: "50%", color: THEME_COLOR }}
                        onClick={async () => {
                            setSessionReport(true)
                            setEndSessionBox(false)
                            if (!window.lesson.isAudioMuted) {
                                if (window.lesson.isAudioMuted !== undefined) {
                                    if (document.getElementById('mic-button-livekit')) {
                                        document.getElementById('mic-button-livekit').click()
                                    }
                                }
                            }
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        sx={{ width: "50%", color: THEME_COLOR }}
                        onClick={(e) => {
                            setEndSessionBox(false)
                        }}
                    >
                        No
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
        <EndSessionStepperForm
            setSessionReport={setSessionReport}
            sessionReport={sessionReport}
            slateID={props.slateID}
            whiteBoardID={props.whiteBoardID}
            accessToken={props.accessToken}
            isTextFocused={props.isTextFocused}
            clientID={props.clientID}
            slateRef={props.slateRef}
            uploadWhiteboardData={props.uploadWhiteboardData}
            isCustomToolbar={true}
        />
    </>

    let exitSessionButton = <>
        <IconButton
            sx={{
                color: '#ffffff',
                marginLeft: '20px',
                borderRadius: '5px',
                display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' },
                border: '1.5px solid #ffffff',
                marginTop: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                marginBottom: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                backgroundColor: THEME_COLOR,
                // width: { xs: '20px', sm: '40px', md: '40px', lg: '60px' },
                "&:hover": { backgroundColor: THEME_COLOR }

            }}
            onClick={(event) => {
                setCloseSessionBox(true)
            }
            }
        >
            <Typography sx={{ fontSize: '0.8rem', fontWeight: 550 }}>
                Exit Class
            </Typography>


        </IconButton>
        <Dialog
            open={closeSessionBox}
            onClose={() => {
                setCloseSessionBox(false)
            }}
        >
            <DialogTitle sx={{ justifyContent: 'center', textAlign: 'center', color: '#B33A3A' }} >
                Warning!
            </DialogTitle>
            <DialogContent>
                <Typography sx={{ fontSize: "1rem" }}>Are you sure you want to exit the session?</Typography>
            </DialogContent>
            <DialogActions>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Button
                        sx={{ width: "50%", color: THEME_COLOR }}
                        onClick={async () => {
                            setCloseSessionBox(false)
                            window.open(FRONTEND_URL, '_self')
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        sx={{ width: "50%", color: THEME_COLOR }}
                        onClick={(e) => {
                            setCloseSessionBox(false)
                        }}
                    >
                        No
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    </>

    // console.log('pan enabled state', isPanEnabled, isToolbarEnabled)
    console.log('Custom toolbar rerendering')

    return (<>
        <ThemeProvider theme={theme}>
            <Snackbar open={openShareNotification} autoHideDuration={3000} onClose={handleCloseShareNotification}>
                <Alert onClose={handleCloseShareNotification} severity="success" sx={{ width: '100%' }}>
                    File Uploaded and Shared!
                </Alert>
            </Snackbar>
            <Snackbar open={openSendMessageNotification} autoHideDuration={3000} onClose={handleCloseSendMessageNotification}>
                <Alert onClose={handleCloseSendMessageNotification} severity="success" sx={{ width: '100%' }}>
                    Message sent to tech support!
                </Alert>
            </Snackbar>
            <Snackbar
                open={openDownloadWhiteboardNotification}
                // open={true}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                style={{ top: '80px', left: '10px' }}
            >
                <Box
                    severity="info"
                    sx={{
                        width: '100%',
                        padding: '12px 12px',
                        borderRadius: '5px',
                        backgroundColor: THEME_COLOR,
                        color: '#ffffff',
                        display: 'flex',
                        // height: '30px'
                    }}
                >
                    <Typography sx={{ fontSize: '0.9rem', marginRight: '10px' }}>
                        Downloading Canvas&nbsp;{downloadingProgress > 0 ? downloadingProgress + '%' : ''}
                    </Typography>
                    <CircularProgress sx={{ height: '17.5px !important', width: '17.5px !important', color: 'white' }} />
                </Box>
            </Snackbar>
            <Snackbar
                open={isUploading}
                // open={true}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                style={{ top: '80px', left: '10px' }}
            >
                <Box
                    severity="info"
                    sx={{
                        width: '100%',
                        padding: '12px 12px',
                        borderRadius: '5px',
                        backgroundColor: THEME_COLOR,
                        color: '#ffffff',
                        display: 'flex',
                        // height: '30px'
                    }}
                >
                    <Typography sx={{ fontSize: '0.9rem', marginRight: '10px' }}>
                        Uploading {totalPagesToUpload > 0 ? pagesUploaded + ' / ' + totalPagesToUpload : ''}
                    </Typography>
                    <CircularProgress sx={{ height: '17.5px !important', width: '17.5px !important', color: 'white' }} />
                </Box>
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={openEmptyNotesNotification}
                autoHideDuration={3000}
                onClose={handleCloseEmptyNotesNotification}
            >
                <Alert onClose={handleCloseEmptyNotesNotification} severity="error" sx={{ width: '100%' }}>
                    No notes have been uploaded so far
                </Alert>
            </Snackbar>
            <Box sx={[{ zIndex: 10, display: "flex", flexDirection: 'column', flexGrow: 0, flexWrap: "wrap", alignItems: 'center', justifyContent: "center", position: 'fixed', bottom: { xs: 0, sm: '10px', md: '10px', lg: '10px' }, width: "100%" }, props.isLoading && { visibility: "hidden" }]}>
                {displayToolbarToggle && <Box
                    sx={{
                        width: '100%',
                        // display: { xs: 'flex', sm: 'none', md: 'none', lg: 'none' }
                    }}>
                    {showBottomToolbar ? <IconButton sx={{

                        left: '48%',
                        color: '#ffffff',
                        backgroundColor: WHITEBOARD_TOOLBAR_COLOR,
                        borderRadius: '5px 5px 0 0',
                        boxShadow: 1,
                        width: '30px',
                        height: "10px",
                        zIndex: 1,
                        fontSize: '1rem',
                        "&:hover": { backgroundColor: WHITEBOARD_TOOLBAR_COLOR }
                    }}

                        onClick={() => {
                            setShowBottomToolbar(false)
                        }}
                    >
                        <ArrowDropDownIcon />

                    </IconButton> :
                        <IconButton sx={{
                            left: '48%',
                            color: '#ffffff',
                            backgroundColor: WHITEBOARD_TOOLBAR_COLOR,
                            borderRadius: '5px 5px 0 0',
                            boxShadow: 1,
                            width: '30px',
                            height: "10px",
                            zIndex: 1,
                            fontSize: '1rem',
                            "&:hover": { backgroundColor: WHITEBOARD_TOOLBAR_COLOR }
                        }}

                            onClick={() => {
                                setShowBottomToolbar(true)
                            }}
                        >
                            <ArrowDropUpIcon />
                        </IconButton>}
                </Box>}


                <Box sx={{
                    maxWidth: { xs: '100%', sm: '50%', md: "60%", lg: '75%' },
                    // display: { xs: showBottomToolbar ? "flex" : 'none', sm: 'flex', md: 'flex', lg: 'flex' },
                    display: showBottomToolbar ? "flex" : 'none',
                    flexGrow: 0,
                    flexWrap: "wrap",
                    justifyContent: "center",
                    backgroundColor: isToolbarEnabled ? WHITEBOARD_TOOLBAR_COLOR : 'none',
                    padding: '0 15px',
                    borderRadius: { xs: '10px 10px 0 0', sm: '10px', md: '10px', lg: '10px' }
                }}>
                    {props.isWhiteboard &&
                        <>
                            {/* {props.isToolBarEnabled && isPanEnabled && !props.isLoading &&
                                <Grid sx={{ position: "fixed", left: 0, bottom: { lg: 0, xs: '150px', sm: '110px', md: "80px" }, [theme.breakpoints.down('300')]: { bottom: "200px" } }}>
                                    <Grid container item justifyContent="center">
                                        <IconButton sx={{ color: THEME_COLOR }} onClick={() => { props.panUp(props.panFactorIcon) }} size={"small"}>
                                            <ArrowDropUpIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            sx={{ color: THEME_COLOR }}
                                            onClick={() => { props.panLeft(props.panFactorIcon) }}
                                            size={"small"}>
                                            <ArrowLeftIcon />
                                        </IconButton>
                                        <IconButton
                                            sx={{ color: THEME_COLOR }}
                                            onClick={() => props._changePan({
                                                clientID: props.clientID,
                                                viewportTransform: [1, 0, 0, 1, 0, 0]
                                            })}
                                            size={"small"}
                                        >
                                            <RotateLeftIcon />
                                        </IconButton>
                                        <IconButton sx={{ color: THEME_COLOR }} onClick={() => { props.panRight(props.panFactorIcon) }} size={"small"}>
                                            <ArrowRightIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item container justifyContent="center">
                                        <IconButton sx={{ color: THEME_COLOR }} onClick={() => { props.panDown(props.panFactorIcon) }} size={"small"}>
                                            <ArrowDropDownIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            } */}

                            {props.isTourEnabled && (props.isTeacher ? <TeacherTour isLoading={props.isLoading} /> : <StudentTour isLoading={props.isLoading} />)}


                            <Box id='record-button-whiteboard' sx={{ paddingTop: '10px' }} md={12}>
                                <RecordingButtonExternal iconColor={iconColor}
                                    recordingTimeRef={props.recordingTimeRef}
                                    whiteBoardID={props.whiteBoardID}
                                    clientID={props.clientID}
                                    accessToken={props.accessToken}
                                    autoRecord={props.autoRecord}
                                />
                            </Box>
                            {isToolbarEnabled && <>
                                <Tooltip placement='top' title={toolName !== "Pencil" ? "Pencil (P)" : " Click pen again to change its color and thickness."}>
                                    <Button
                                        id='pencil-button-whiteboard'
                                        // className={classes.iconButton}
                                        sx={{
                                            height: "80px", width: '50px', overflow: 'hidden',
                                            minWidth: '50px',
                                            padding: toolName === "Pencil" ? '50px 0 0' : '80px 0 0',
                                            '&:hover': {
                                                padding: toolName === "Pencil" ? '50px 0 0' : '65px 0 0',
                                            }
                                        }}
                                        onClick={(event) => {
                                            if (toolName === "Pencil") {
                                                setOpenLineColorPicker(event.currentTarget)
                                            }
                                            else {

                                                _selectTool({ target: { value: "Pencil" } })
                                            }

                                        }}
                                    >

                                        <svg _ngcontent-pln-c462="" width="50" height="156" viewBox="0 0 54 156" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g _ngcontent-pln-c462="" filter="url(#filter0_d)">
                                                <path _ngcontent-pln-c462="" d="M25 18C25 16.8954 25.8954 16 27 16C28.1046 16 29 16.8954 29 18L29 24L25 24L25 18Z" fill={lineColor}>

                                                </path>
                                                <path _ngcontent-pln-c462="" d="M16 44.3265C16 44.0841 16.044 43.8438 16.13 43.6173L21 30.7758L23.516 24.2546C23.8087 23.496 24.5315 22.9901 25.3445 22.9748L28.5933 22.914C29.4372 22.8982 30.2001 23.4139 30.5001 24.2027L33 30.7758L37.87 43.6173C37.956 43.8438 38 44.0841 38 44.3265L38 138C38 139.104 37.1046 140 36 140L18 140C16.8954 140 16 139.104 16 138L16 44.3265Z" fill="#ffffff">

                                                </path>
                                                <rect _ngcontent-pln-c462="" x="16" y="53" width="22" height="4" fill={lineColor}>

                                                </rect>
                                            </g>
                                            <defs _ngcontent-pln-c462=""><filter _ngcontent-pln-c462="" id="filter0_d" x="0" y="0" width="54" height="156" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                <feFlood _ngcontent-pln-c462="" floodOpacity="0" result="BackgroundImageFix">

                                                </feFlood>
                                                <feColorMatrix _ngcontent-pln-c462="" in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha">
                                                </feColorMatrix>
                                                <feOffset _ngcontent-pln-c462="">
                                                </feOffset>
                                                <feGaussianBlur _ngcontent-pln-c462="" stdDeviation="8">
                                                </feGaussianBlur>
                                                <feColorMatrix _ngcontent-pln-c462="" type="matrix" values="0 0 0 0 0.25 0 0 0 0 0.25 0 0 0 0 0.25 0 0 0 0.25 0">
                                                </feColorMatrix><feBlend _ngcontent-pln-c462="" mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow">
                                                </feBlend><feBlend _ngcontent-pln-c462="" mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape">
                                                </feBlend></filter>
                                                <linearGradient _ngcontent-pln-c462="" id="paint0_linear" x1="9" y1="91" x2="44" y2="91" gradientUnits="userSpaceOnUse">
                                                    <stop _ngcontent-pln-c462="" stopColor="#E8E8E8"></stop><stop _ngcontent-pln-c462="" offset="0.505208" stopColor="white"></stop><stop _ngcontent-pln-c462="" offset="1" stopColor="#E8E8E8">

                                                    </stop>
                                                </linearGradient>
                                                <linearGradient _ngcontent-pln-c462="" id="paint1_linear" x1="16" y1="62" x2="38" y2="62" gradientUnits="userSpaceOnUse"><stop _ngcontent-pln-c462=""></stop><stop _ngcontent-pln-c462="" offset="0.463542" stopOpacity="0"></stop><stop _ngcontent-pln-c462="" offset="1">
                                                </stop>
                                                </linearGradient>
                                            </defs>
                                        </svg>

                                        {/* <CreateIcon /> */}
                                    </Button>

                                </Tooltip>
                                {/* <Popover
                                    open={openClickPrompt}
                                    anchorEl={openClickPrompt}
                                    onClose={() => {
                                        setOpenClickPrompt(false)

                                    }}
                                    anchorOrigin={{
                                        vertical: -20,
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Box sx={{ backgroundColor: "#525252" }}>
                                        <Typography sx={{ fontSize: '0.8rem', color: '#FFFFFF', padding: '5px 10px', fontWeight: 500 }}>
                                            Click/tap pen again to change its color and thickness.
                                        </Typography>
                                    </Box>

                                </Popover> */}
                                <Tooltip placement='top' title={toolName !== "Highlighter" ? "Highlighter (H) " : " Click pen again to change its color and thickness."}>

                                    <Button
                                        sx={{
                                            height: "80px", width: '50px', overflow: 'hidden',
                                            minWidth: '50px',
                                            padding: toolName === "Highlighter" ? '30px 0 0' : '60px 0 0',
                                            '&:hover': {
                                                padding: toolName === "Highlighter" ? '30px 0 0' : '45px 0 0',
                                            }
                                        }}
                                        onClick={(event) => {

                                            if (toolName === "Highlighter") {
                                                setOpenHighlighterColorPicker(event.currentTarget)
                                            }
                                            else {
                                                _selectTool({ target: { value: "Highlighter" } })
                                            }
                                            //  setOpenLineColorPicker(event.currentTarget)
                                        }}

                                    >
                                        <svg _ngcontent-pln-c462="" width="50" height="159" viewBox="0 0 67 159" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g _ngcontent-pln-c462="" filter="url(#filter1_d)">
                                                <path _ngcontent-pln-c462="" d="M28 19.7638C28 19.3128 28.3018 18.9177 28.7369 18.799L37.7369 16.3445C38.373 16.171 39 16.6499 39 17.3093V29H28L28 19.7638Z" fill={highlighterColor} >
                                                </path>
                                                <path _ngcontent-pln-c462="" fillRule="evenodd" clipRule="evenodd" d="M18.4778 56.7691C16.8709 58.8644 16 61.4311 16 64.0716V141C16 142.105 16.8954 143 18 143H31H36H49C50.1046 143 51 142.105 51 141L51 64.0716C51 61.4311 50.1291 58.8644 48.5222 56.7691L42.6518 49.1143C41.5806 47.7174 41 46.0063 41 44.2459V30.5C41 29.3954 40.1046 28.5 39 28.5H36H31H28C26.8954 28.5 26 29.3954 26 30.5L26 44.2459C26 46.0063 25.4194 47.7174 24.3482 49.1143L18.4778 56.7691Z" fill="#ffffff">
                                                </path>
                                                <rect _ngcontent-pln-c462="" x="16" y="72" width="35" height="4" fill={highlighterColor}>
                                                </rect>
                                            </g>
                                            <defs _ngcontent-pln-c462="">
                                                <filter _ngcontent-pln-c462="" id="filter1_d" x="0" y="0" width="67" height="156" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                    <feFlood _ngcontent-pln-c462="" floodOpacity="0" result="BackgroundImageFix">
                                                    </feFlood>
                                                    <feColorMatrix _ngcontent-pln-c462="" in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha">
                                                    </feColorMatrix>
                                                    <feOffset _ngcontent-pln-c462="">
                                                    </feOffset>
                                                    <feGaussianBlur _ngcontent-pln-c462="" stdDeviation="8">
                                                    </feGaussianBlur>
                                                    <feColorMatrix _ngcontent-pln-c462="" type="matrix" values="0 0 0 0 0.25 0 0 0 0 0.25 0 0 0 0 0.25 0 0 0 0.25 0">
                                                    </feColorMatrix>
                                                    <feBlend _ngcontent-pln-c462="" mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow">
                                                    </feBlend>
                                                    <feBlend _ngcontent-pln-c462="" mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape">
                                                    </feBlend>
                                                </filter>
                                                <linearGradient _ngcontent-pln-c462="" id="paint0_linear" x1="9" y1="91" x2="44" y2="91" gradientUnits="userSpaceOnUse">
                                                    <stop _ngcontent-pln-c462="" stopColor="#E8E8E8">
                                                    </stop>
                                                    <stop _ngcontent-pln-c462="" offset="0.505208" stopColor="white">
                                                    </stop>
                                                    <stop _ngcontent-pln-c462="" offset="1" stopColor="#E8E8E8">
                                                    </stop>
                                                </linearGradient>
                                                <linearGradient _ngcontent-pln-c462="" id="paint1_linear" x1="16" y1="62" x2="38" y2="62" gradientUnits="userSpaceOnUse">
                                                    <stop _ngcontent-pln-c462="">
                                                    </stop>
                                                    <stop _ngcontent-pln-c462="" offset="0.463542" stopColor="0">
                                                    </stop>
                                                    <stop _ngcontent-pln-c462="" offset="1">
                                                    </stop>
                                                </linearGradient>
                                            </defs>
                                        </svg>



                                        {/* <CreateIcon /> */}
                                    </Button>

                                </Tooltip>
                                <Tooltip title="Eraser (E)">
                                    <Button
                                        sx={{
                                            height: "80px", width: '50px', overflow: 'hidden',
                                            minWidth: '50px',
                                            padding: toolName === "Eraser" ? '30px 0 0' : '60px 0 0',
                                            '&:hover': {
                                                padding: toolName === "Eraser" ? '30px 0 0' : '45px 0 0',
                                            }
                                        }}
                                        onClick={(event) => {
                                            _selectTool({ target: { value: "Eraser" } })
                                        }}
                                        style={toolName === "Eraser" ? styles.selected : {}}
                                        size="medium">
                                        <svg width="50" height="159" viewBox="0 0 67 159" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g filter="url(#filter0_d)">
                                                <path d="M38 127.673C38 127.915 37.956 128.156 37.87 128.382L33 141.224L28.8694 152.085C28.2157 153.803 25.7843 153.803 25.1306 152.085L21 141.224L16.13 128.382C16.044 128.156 16 127.915 16 127.673L16 34C16 32.8954 16.8954 32 18 32L36 32C37.1046 32 38 32.8954 38 34L38 127.673Z" fill="url(#paint0_linear)" />
                                                <rect x="16" y="16" width="22" height="23" rx="4" fill="#F4ACAC" />
                                                <rect x="16" y="16" width="22" height="23" rx="4" fill="url(#paint1_linear)" fillOpacity="0.2" />
                                                <rect x="16" y="35" width="22" height="7" fill="url(#paint2_linear)" />
                                            </g>
                                            <defs>
                                                <filter id="filter0_d" x="0" y="0" width="54" height="169.374" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                    <feOffset />
                                                    <feGaussianBlur stdDeviation="8" />
                                                    <feColorMatrix type="matrix" values="0 0 0 0 0.25 0 0 0 0 0.25 0 0 0 0 0.25 0 0 0 0.25 0" />
                                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                                                </filter>
                                                <linearGradient id="paint0_linear" x1="45" y1="81" x2="10" y2="81" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#E8E8E8" />
                                                    <stop offset="0.505208" stopColor="white" />
                                                    <stop offset="1" stopColor="#E8E8E8" />
                                                </linearGradient>
                                                <linearGradient id="paint1_linear" x1="16" y1="28.3846" x2="38" y2="28.3846" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#E5616D" />
                                                    <stop offset="0.484375" stopColor="#E5616D" stopOpacity="0" />
                                                    <stop offset="1" stopColor="#E5616D" />
                                                </linearGradient>
                                                <linearGradient id="paint2_linear" x1="16" y1="39" x2="38" y2="39" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#666666" />
                                                    <stop offset="0.458333" stopColor="#8C8C8C" />
                                                    <stop offset="1" stopColor="#666666" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </Button>
                                </Tooltip>
                                {(isPanEnabled) && <Tooltip title={"Pan (D)"}>
                                    <IconButton
                                        id='pan-button-whiteboard'
                                        sx={{ color: iconColor }}
                                        onClick={(event) => {
                                            _selectTool({ target: { value: "Pan" } })
                                        }}
                                        style={toolName === "Pan" ? styles.selected : {}}
                                        size="medium">
                                        <PanToolIcon />
                                    </IconButton>
                                </Tooltip>
                                }

                                {/* <Tooltip title="Add SlatePad">
                                    <IconButton
                                        onClick={() => {
                                            props._sketch.current.addIframeTag("https://server1.slatemates.in:9001/p/" + uuid4() + "?showControls=false&showChat=false&showLineNumbers=true&useMonospaceFont=false", true)
                                        }}
                                        sx={{
                                            color: iconColor,
                                            display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' },
                                        }}
                                        size="medium"
                                    >
                                        <AssignmentIcon />
                                    </IconButton>
                                </Tooltip> */}
                                <Tooltip title="Add text (T)">
                                    <IconButton
                                        onClick={() => {
                                            props._addText()
                                        }}
                                        sx={{
                                            color: iconColor,
                                            display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' },
                                        }}
                                        size="medium"
                                    >
                                        <TitleIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={"Select (S)"}>
                                    <IconButton
                                        sx={{ color: iconColor }}
                                        onClick={(event) => {
                                            _selectTool({ target: { value: "Select" } })
                                        }}
                                        style={toolName === "Select" ? styles.selected : {}}
                                        size="medium">
                                        <NearMeIcon />
                                    </IconButton>
                                </Tooltip>
                                {/* <Tooltip title="Remove selected (Del/Backspace)">
                                <IconButton
                                    sx={{ color: iconColor }}
                                    onClick={props._removeSelected}
                                    size="medium">
                                    <HighlightOffIcon />
                                </IconButton>
                            </Tooltip> */}



                                <Tooltip title="Shapes">
                                    <IconButton
                                        sx={{ color: iconColor, display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' }, }}
                                        onClick={(event) => {
                                            setOpenShapes(event.currentTarget)
                                        }}

                                        size="medium">
                                        <CategoryIcon />
                                    </IconButton>
                                </Tooltip>
                                <Popover
                                    open={Boolean(openShapes)}
                                    anchorEl={openShapes}
                                    onClose={() => {
                                        setOpenShapes(false)
                                    }}
                                    anchorOrigin={{
                                        vertical: -10,
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Tooltip title={"Circle (C)"} placement="top">
                                        <IconButton
                                            sx={{ color: lineColor }}
                                            onClick={(event) => {
                                                _selectTool({ target: { value: "Circle" } })
                                                setOpenShapes(false)
                                            }}
                                            style={toolName === "Circle" ? styles.selected : {}}
                                            size="large">
                                            <RadioButtonUncheckedIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={"Rectangle"} placement="top">
                                        <IconButton
                                            sx={{ color: lineColor }}
                                            onClick={(event) => {
                                                _selectTool({ target: { value: "Rectangle" } })
                                                setOpenShapes(false)
                                            }}
                                            style={toolName === "Rectangle" ? styles.selected : {}}
                                            size="large">
                                            <Crop169Icon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={"Line (L)"} placement="top">
                                        <IconButton
                                            sx={{ color: lineColor }}
                                            onClick={(event) => {
                                                _selectTool({ target: { value: "Line" } })
                                                setOpenShapes(false)
                                            }}
                                            style={toolName === "Line" ? styles.selected : {}}
                                            size="large">
                                            <RemoveIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={"Arrow (A)"} placement="top">
                                        <IconButton
                                            sx={{ color: lineColor }}
                                            onClick={(event) => {
                                                _selectTool({ target: { value: "Arrow" } })
                                                setOpenShapes(false)
                                            }}
                                            style={toolName === "Arrow" ? styles.selected : {}}
                                            size="large">
                                            <ArrowRightAltIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={"Dotted Line"} placement="top">
                                        <IconButton
                                            sx={{ color: lineColor }}
                                            onClick={(event) => {
                                                // props._sketch.current.addDottedLine()
                                                _selectTool({ target: { value: "DashedLine" } })
                                                setOpenShapes(false)
                                            }}
                                            size="large">
                                            <MoreHorizIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={"Right angled triangle"} placement="top">
                                        <IconButton
                                            sx={{ color: lineColor }}
                                            onClick={(event) => {
                                                props._sketch.current.addRightAngledTriangle(lineColor)
                                            }}
                                            size="large">
                                            <ChangeHistoryIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={"Hexagon"} placement="top">
                                        <IconButton
                                            sx={{ color: lineColor }}
                                            onClick={(event) => {
                                                props._addPolyline(lineColor)
                                            }}
                                            size="large">
                                            <HexagonIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Popover>

                                <Box
                                    id='zoom-button-whiteboard'
                                    sx={{
                                        display: { xs: 'none', sm: 'flex', md: 'flex', lg: 'flex' },
                                        position: 'fixed',
                                        bottom: '10px',
                                        right: '10px',
                                        backgroundColor: '#ffffff',
                                        padding: '6px',
                                        borderRadius: '10px',
                                        boxShadow: 1,
                                        flexDirection: 'column'
                                    }}
                                >

                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        {props.isZoomEnabled && <>
                                            <Button
                                                sx={{ color: openZoomBox ? THEME_COLOR : "#000000", display: 'flex', flexDirection: 'row' }}
                                                onClick={(e) => {
                                                    setOpenZoomBox(e.currentTarget)
                                                }}
                                                size="small"
                                            >
                                                <ZoomInIcon />
                                                <Typography sx={{ fontSize: '0.85rem', fontWeight: 550, marginLeft: '5px', marginRight: '5px' }}>
                                                    {parseInt(Math.ceil((canvasZoomLevel) * 100))}%
                                                </Typography>

                                                <KeyboardArrowUpIcon />
                                            </Button>
                                            <Popover
                                                open={openZoomBox}
                                                anchorEl={openZoomBox}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                onClose={() => {
                                                    setOpenZoomBox(false)
                                                }}
                                            >
                                                <Box sx={{ position: 'relative', overscrollBehavior: "none", maxHeight: '100%', maxWidth: '300px', minHeight: '25px' }} >
                                                    <Grid container sx={{ color: 'black', height: '30px', position: 'sticky', right: 0, top: 0, zIndex: 10000, width: '100%' }} justifyContent='right' textAlign='right'>

                                                        <IconButton
                                                            sx={{ color: 'black', position: 'sticky', zIndex: 10000 }}
                                                            onClick={() => {
                                                                setOpenZoomBox(false)
                                                            }}
                                                            size="small">
                                                            <CloseIcon sx={{ fontSize: '1rem' }} />
                                                            {/* <CloseIcon sx={{ fontSize: '1rem' }} /> */}
                                                        </IconButton>

                                                    </Grid>
                                                    <Divider sx={{ color: '#f8f8f8', height: '1px' }}></Divider>
                                                    <Box sx={{ padding: '10px 15px', display: 'flex', flexDirection: 'column' }}>

                                                        <Box sx={{ padding: '10px 15px', display: 'flex', flexDirection: 'row' }}>
                                                            <Tooltip title="Zoom out (O)">
                                                                <>
                                                                    <Button
                                                                        sx={{
                                                                            border: '1px solid rgb(224, 224, 224)',
                                                                            backgroundColor: '#f8f8f8',
                                                                            padding: "5px 10px",
                                                                            color: '#000000'
                                                                        }}
                                                                        onClick={(e) => {
                                                                            props.onNewZoom(props.zoomOutFactor);
                                                                        }}
                                                                        disabled={canvasZoomLevel * props.zoomOutFactor < MIN_ZOOM}
                                                                    >
                                                                        <RemoveIcon />
                                                                    </Button>
                                                                </>
                                                            </Tooltip>
                                                            <Tooltip title="Zoom in (I)">
                                                                <>
                                                                    <Button
                                                                        sx={{
                                                                            border: '1px solid rgb(224, 224, 224)',
                                                                            backgroundColor: '#f8f8f8',
                                                                            padding: "5px 10px",
                                                                            marginLeft: '10px',
                                                                            color: '#000000'
                                                                        }}
                                                                        onClick={(e) => {
                                                                            props.onNewZoom(props.zoomInFactor);
                                                                        }}
                                                                        disabled={canvasZoomLevel * props.zoomInFactor > MAX_ZOOM}
                                                                    >
                                                                        <AddIcon />
                                                                    </Button>
                                                                </>
                                                            </Tooltip>
                                                        </Box>
                                                        <Box sx={{ padding: '10px 15px', display: 'flex' }}>
                                                            <Button
                                                                sx={{
                                                                    border: '1px solid rgb(224, 224, 224)',
                                                                    backgroundColor: '#f8f8f8',
                                                                    padding: "5px 0",
                                                                    width: '100%',
                                                                    color: '#000000',
                                                                    textTransform: 'none'
                                                                }}
                                                                onClick={props.onNewZoomRestore}
                                                            >
                                                                <CenterFocusStrongIcon />
                                                                &nbsp; Reset
                                                            </Button>
                                                        </Box>
                                                        <Box sx={{ padding: '10px 15px', display: 'flex' }}>
                                                            <Button
                                                                sx={{
                                                                    border: '1px solid rgb(224, 224, 224)',
                                                                    backgroundColor: '#f8f8f8',
                                                                    padding: "5px 0",
                                                                    width: '100%',
                                                                    color: '#000000',
                                                                    textTransform: 'none'
                                                                }}
                                                                onClick={() => {
                                                                    if (props._sketch.current)
                                                                        props._sketch.current.zoomToFitObjects(true)
                                                                }}
                                                            >
                                                                <CenterFocusStrongIcon />
                                                                &nbsp; Zoom by width
                                                            </Button>
                                                        </Box>
                                                        <Box sx={{ padding: '10px 15px', display: 'flex' }}>
                                                            <Button
                                                                sx={{
                                                                    border: '1px solid rgb(224, 224, 224)',
                                                                    backgroundColor: '#f8f8f8',
                                                                    padding: "5px 0",
                                                                    width: '100%',
                                                                    color: '#000000',
                                                                    textTransform: 'none'
                                                                }}
                                                                onClick={() => {
                                                                    if (props._sketch.current)
                                                                        props._sketch.current.zoomToFitObjects(false)
                                                                }}
                                                            >
                                                                <CenterFocusStrongIcon />
                                                                &nbsp; Zoom to fit
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Popover>
                                        </>
                                        }
                                        <ShortcutGuide />
                                    </Box>
                                    {props.isZoomEnabled && <Box sx={{ padding: '2.5px 7.5px', display: 'flex', flexDirection: 'row' }}>
                                        <Slider
                                            sx={{ width: '100%', color: THEME_COLOR }}
                                            value={zoomSliderLevel}
                                            step={0.1}
                                            onChange={(e, newValue) => {
                                                if (typeof newValue === 'number') {
                                                    if (newValue >= MIN_ZOOM && newValue <= MAX_ZOOM) {

                                                        if (zoomSliderLevel !== newValue) {
                                                            // console.log('new value is,', newValue)
                                                            props.onNewZoomAbsolute(newValue)
                                                        }

                                                    }
                                                }

                                            }}
                                            min={MIN_ZOOM}
                                            max={MAX_ZOOM}
                                        />
                                    </Box>}
                                    {/* {props.isZoomEnabled && <Box
                                        sx={{
                                            width: '100%',
                                            paddingBottom: '10px',
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            color: 'grey'
                                        }}
                                    >

                                    </Box>} */}
                                    {/* <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                        {props.isZoomEnabled && <Tooltip title="Zoom in (I)">
                                            <IconButton
                                                sx={{ color: "#000000" }}
                                                onClick={(e) => {
                                                    props.onNewZoom(props.zoomInFactor);
                                                }}
                                                size="small"
                                                disabled={canvasZoomLevel * props.zoomInFactor > MAX_ZOOM}
                                            >
                                                <ZoomInIcon />
                                            </IconButton>
                                        </Tooltip>
                                        }
                                        {props.isZoomEnabled &&
                                            <Tooltip title="Restore zoom">
                                                <IconButton sx={{ color: "#000000" }} onClick={props.onNewZoomRestore} size="small">
                                                    <YoutubeSearchedForIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        {props.isZoomEnabled &&
                                            <Tooltip title="Zoom out (O)">
                                                <IconButton
                                                    id="zoomOut-button"
                                                    sx={{ color: "#000000" }}
                                                    onClick={(e) => {
                                                        props.onNewZoom(props.zoomOutFactor);
                                                    }}
                                                    size="small"
                                                    disabled={canvasZoomLevel * props.zoomOutFactor < MIN_ZOOM}
                                                >
                                                    <ZoomOutIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        <ShortcutGuide />
                                    </Box> */}

                                </Box>
                                <Box id='zoom-button-whiteboard' sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' } }}>
                                    {props.isZoomEnabled && <Tooltip title="Zoom in (I)">
                                        <IconButton
                                            sx={{ color: iconColor }}
                                            onClick={(e) => {
                                                props.onNewZoom(props.zoomInFactor);
                                            }}
                                            size="medium"
                                            disabled={canvasZoomLevel * props.zoomInFactor > MAX_ZOOM}
                                        >
                                            <ZoomInIcon />
                                        </IconButton>
                                    </Tooltip>
                                    }
                                    {props.isZoomEnabled &&
                                        <Tooltip title="Restore zoom">
                                            <IconButton sx={{ color: iconColor }} onClick={props.onNewZoomRestore} size="medium">
                                                <YoutubeSearchedForIcon />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    {props.isZoomEnabled &&
                                        <Tooltip title="Zoom out (O)">
                                            <IconButton
                                                id="zoomOut-button"
                                                sx={{ color: iconColor }}
                                                onClick={(e) => {
                                                    props.onNewZoom(props.zoomOutFactor);
                                                }}
                                                size="medium"
                                                disabled={canvasZoomLevel * props.zoomOutFactor < MIN_ZOOM}
                                            >
                                                <ZoomOutIcon />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </Box>
                                <UndoRedoButton _sketch={props._sketch} iconColor={iconColor} />
                                <Tooltip title="Upload Files (U)">
                                    <IconButton
                                        id='upload-files-button-whiteboard'
                                        sx={{ color: iconColor }}
                                        onClick={() => document.getElementById("file-to-upload").click()}
                                        size="medium">
                                        <AttachFileIcon />
                                    </IconButton>
                                </Tooltip>
                                <input
                                    type="file"
                                    id="file-to-upload"
                                    accept="/*"
                                    hidden
                                    onChange={async (e) => {
                                        const file = document.getElementById('file-to-upload').files[0];
                                        if (file) {
                                            const isPdf = file.type === 'application/pdf'
                                            const isImage = file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/webp'
                                            const isMedia = file.type === 'audio/mpeg' || file.type === 'video/mp4' || file.type.includes('audio') || file.type.includes('video')
                                            const isTextFile = (file.name).includes('.whiteboardData') || file.type === 'text/plain' || file.type === 'text/json'
                                            const isPptx = file.type.includes('application/vnd') && file.type.includes('presentationml')
                                            const isDocx = file.type.includes('application/vnd') && file.type.includes('wordprocessingml')
                                            const isDoc = file.type.includes('application/msword')
                                            const isPpt = file.type.includes('application/vnd') && file.type.includes('ms-powerpoint')
                                            const isXlsx = file.type.includes('application/vnd') && file.type.includes('spreadsheetml')
                                            const isXls = file.type.includes('application/vnd') && file.type.includes('ms-excel')
                                            console.log(file.type);

                                            if (isPdf) {
                                                // props.handleChangeLoading(true)
                                                handleIsPdf(e, null)
                                                // props.handleChangeLoading(false)
                                            }

                                            else if (isPptx) {
                                                handlePptxDocs(e, 'pptx')
                                            }
                                            else if (isDocx) {
                                                handlePptxDocs(e, 'docx')
                                            }
                                            else if (isDoc) {
                                                handlePptxDocs(e, 'doc')
                                            }
                                            else if (isPpt) {
                                                handlePptxDocs(e, 'ppt')
                                            }
                                            else if (isXlsx) {
                                                handlePptxDocs(e, 'xlsx')
                                            }
                                            else if (isXls) {
                                                handlePptxDocs(e, 'xls')
                                            }
                                            else if (isImage) {
                                                props.addImageUrl(
                                                    e, props._sketch.current.addImg,
                                                    handleChangeUploading,
                                                    props.whiteBoardID,
                                                    props.enqueueSnackbar,
                                                    props.accessToken,
                                                )

                                            }
                                            else if (isMedia) {
                                                props.uploadMediaAndPlay(
                                                    e,
                                                    props.accessToken,
                                                    handleChangeUploading,
                                                    file.type
                                                )

                                            }
                                            else if (isTextFile) {
                                                props.handleUploadWhiteboard(e)
                                            }
                                            else {
                                                alert("File type not supported")
                                                document.getElementById('file-to-upload').value = null;
                                            }
                                        }
                                    }}
                                />
                                <Dialog fullWidth maxWidth="lg" open={openPdfBox}
                                    // onClose={() => {
                                    //     handleClosePdfWindow()
                                    // }}
                                    fullScreen={fullScreen}
                                >
                                    {pdfImagesArray.length > 0 ? <><DialogTitle sx={{ backgroundColor: 'rgb(248, 248, 248)', justifyContent: 'left', textAlign: 'left', position: 'relative' }}>
                                        <IconButton
                                            sx={{ color: 'black', position: 'absolute', right: '5px', top: "5px", zIndex: 10000 }}
                                            onClick={() => {
                                                handleClosePdfWindow()
                                            }}
                                            size="small">
                                            <CloseIcon sx={{ fontSize: '1rem' }} />
                                        </IconButton>
                                        <Button
                                            sx={{ padding: '5px 10px', color: THEME_COLOR, boxShadow: '1', marginLeft: '20px', textTransform: 'none' }}
                                            onClick={(e) => {
                                                // console.log(pdfImagesArray)
                                                setPdfToPasteArray(pdfImagesArray)
                                            }}
                                        >
                                            Select All
                                        </Button>
                                        <Button
                                            sx={{ padding: '5px 10px', color: THEME_COLOR, boxShadow: '1', marginLeft: '10px', textTransform: 'none' }}
                                            onClick={(e) => {
                                                setPdfToPasteArray([])
                                            }}
                                        >
                                            Reset
                                        </Button>
                                    </DialogTitle>
                                        <DialogContent sx={{ backgroundColor: 'rgb(248, 248, 248)', justifyContent: 'center' }} >
                                            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: '10px' }}>
                                                {pdfImagesArray.map((element) => {

                                                    return <Card sx={{ position: 'relative', height: '50%', width: '30%', marginLeft: '20px', marginBottom: '20px', boxShadow: '1' }} >

                                                        {pdfToPasteArray.includes(element) ?
                                                            <IconButton
                                                                sx={{
                                                                    '&:hover': {
                                                                        backgroundColor: 'rgb(248, 248, 248)',
                                                                        boxShadow: 'none',
                                                                    },
                                                                    backgroundColor: 'rgb(224, 224, 224)', position: 'absolute', top: 5, right: 5, height: '30px', width: '30px', boxShadow: '1', borderRadius: '2.5px'
                                                                }}
                                                                onClick={() => {
                                                                    const newList = pdfToPasteArray.filter((data) => data.pageIndex !== element.pageIndex);
                                                                    // console.log(newList)
                                                                    setPdfToPasteArray(newList)
                                                                }}
                                                            >
                                                                <DoneIcon sx={{ color: 'green', fontSize: '1rem' }} />
                                                            </IconButton> :
                                                            <IconButton
                                                                sx={{
                                                                    '&:hover': {
                                                                        backgroundColor: 'rgb(248, 248, 248)',
                                                                        boxShadow: 'none',
                                                                    },
                                                                    backgroundColor: 'rgb(224, 224, 224)', position: 'absolute', top: 5, right: 5, height: '30px', width: '30px', boxShadow: '1', borderRadius: '2.5px'
                                                                }}
                                                                onClick={() => {
                                                                    setPdfToPasteArray(prevValue => {
                                                                        return [...prevValue, element]
                                                                    })
                                                                }}
                                                            >
                                                            </IconButton>}

                                                        <img src={element.src} height='100%' width='100%'>

                                                        </img>
                                                    </Card>
                                                })
                                                }
                                            </Box>
                                        </DialogContent>
                                        <DialogActions sx={{ backgroundColor: 'rgb(248, 248, 248)', }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                <Button
                                                    sx={{ padding: '5px 10px', color: THEME_COLOR, boxShadow: '1', textTransform: 'none' }}
                                                    onClick={async () => {
                                                        handleClosePdfWindow()
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    sx={{ padding: '5px 10px', color: THEME_COLOR, boxShadow: '1', textTransform: 'none', marginLeft: '10px' }}
                                                    onClick={(e) => {
                                                        const sortedArray = pdfToPasteArray.sort(function (a, b) {
                                                            return a.pageIndex - b.pageIndex;
                                                        });
                                                        // console.log(sortedArray)
                                                        props.pasteImagesAsPdf(
                                                            sortedArray,
                                                            props._sketch.current.addImg,
                                                            props.panToNewPdf,
                                                            handleChangeUploading,
                                                            props.accessToken,
                                                            props.whiteBoardID,
                                                            fileUploadName,
                                                            handleTotalPagesToUpload,
                                                            handlePagesUploaded
                                                        )
                                                        handleClosePdfWindow()
                                                    }}
                                                    disabled={pdfToPasteArray.length === 0}
                                                >
                                                    Upload
                                                </Button>
                                            </Box>
                                        </DialogActions>
                                    </> :
                                        <Grid sx={{
                                            position: 'relative',
                                            width: "100%", height: "80vh", opacity: "1",
                                            backgroundColor: 'rgb(248, 248, 248)', display: "flex", alignItems: "center", alignContent: "center", justifyContent: "center"
                                        }}>
                                            <IconButton
                                                sx={{ color: 'black', position: 'absolute', right: '5px', top: "5px", zIndex: 10000 }}
                                                onClick={() => {
                                                    handleClosePdfWindow()
                                                }}
                                                size="small">
                                                <CloseIcon sx={{ fontSize: '1rem' }} />
                                            </IconButton>
                                            <Box sx={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                                                <Typography sx={{
                                                    color: THEME_COLOR,
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                    fontSize: '1.25rem'
                                                }}>
                                                    Loading contents {pdfLoader > 0 && <span style={{ color: 'black' }}>{pdfLoader}%</span>}
                                                </Typography>
                                                <Box sx={{
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                }}>
                                                    <CustomLoader />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    }
                                </Dialog>

                                <TextField
                                    label="Text"
                                    helperText="Add text to Sketch"
                                    onChange={(e) => props.handleText(e.target.value)}
                                    value={props.text}
                                    placeholder={"type here"}
                                    style={{ display: "none" }}
                                />
                                <Tooltip title={"Download"}>
                                    <IconButton
                                        sx={{ color: iconColor }}
                                        onClick={(e) => {
                                            setOpenDownloadWhiteboardBox(e.currentTarget)
                                        }
                                        }
                                        size="medium">
                                        <DownloadIcon />
                                    </IconButton>
                                </Tooltip>
                                <Popover
                                    open={openDownloadWhiteboardBox}
                                    anchorEl={openDownloadWhiteboardBox}
                                    onClose={() => setOpenDownloadWhiteboardBox(false)}
                                    anchorOrigin={{
                                        vertical: -10,
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Box sx={{ position: 'relative', overscrollBehavior: "none", maxHeight: '100%', maxWidth: '300px', minHeight: '25px' }} >
                                        <Grid container sx={{ color: 'black', height: '20px', position: 'sticky', right: 0, top: 0, zIndex: 10000, width: '100%' }} justifyContent='right' textAlign='right'>

                                            <IconButton
                                                sx={{ color: 'black', position: 'sticky', zIndex: 10000 }}
                                                onClick={() => {
                                                    setOpenDownloadWhiteboardBox(false)
                                                }}
                                                size="small">
                                                <CloseIcon sx={{ fontSize: '1rem' }} />
                                                {/* <CloseIcon sx={{ fontSize: '1rem' }} /> */}
                                            </IconButton>

                                        </Grid>
                                        <Box sx={{ padding: '10px 15px' }}>

                                            <Table sx={{ tableLayout: "fixed" }} >

                                                <TableBody >

                                                    {props.isTeacher && <TableRow sx={{
                                                        maxWidth: '100%',
                                                        cursor: 'pointer',
                                                        '&:hover': {
                                                            boxShadow: 1,
                                                        },

                                                    }}
                                                        onClick={() => {
                                                            props.handleDownloadWhiteboard()
                                                        }}
                                                    >
                                                        <TableCell align="left" sx={{ padding: '2px 5px', color: '#000000', width: '80%', borderBottom: "none", wordWrap: "break-word", fontWeight: 540, fontSize: "0.8rem" }}>
                                                            Download whiteboard as text file
                                                        </TableCell>
                                                        <TableCell align="left" sx={{ color: '#828282', width: '20%', padding: 0, borderBottom: "none" }}>

                                                            <IconButton sx={{ color: THEME_COLOR }}>
                                                                <DownloadIcon />
                                                            </IconButton>

                                                        </TableCell>

                                                    </TableRow>}

                                                    <TableRow sx={{
                                                        maxWidth: '100%',
                                                        cursor: 'pointer',
                                                        '&:hover': {
                                                            boxShadow: 1,
                                                        },

                                                    }}
                                                        onClick={() => {

                                                            if (props._sketch.current) {
                                                                props.downloadCanvasAsImage(props._sketch.current._fc.current, setOpenDownloadWhiteboardNotification, props.whiteBoardID, setDownloadingProgress)
                                                            }

                                                        }
                                                        }
                                                    >
                                                        <TableCell align="left" sx={{ padding: '2px 5px', color: '#000000', width: '80%', borderBottom: "none", wordWrap: "break-word", fontWeight: 540, fontSize: "0.8rem" }}>
                                                            Download canvas pdf
                                                        </TableCell>
                                                        <TableCell align="left" sx={{ color: '#828282', width: '20%', padding: 0, borderBottom: "none" }}>

                                                            <IconButton sx={{ color: THEME_COLOR }}>
                                                                <PictureAsPdfIcon />
                                                            </IconButton>

                                                        </TableCell>

                                                    </TableRow>
                                                    <TableRow sx={{
                                                        maxWidth: '100%',
                                                        cursor: 'pointer',
                                                        '&:hover': {
                                                            boxShadow: 1,
                                                        },

                                                    }}
                                                        onClick={() => {

                                                            downloadItems("notes/" + props.clientID + "/", props.accessToken, props.whiteBoardID, props.slateRef, props.userName)
                                                                .then(result => {
                                                                    if (result === null) {
                                                                        setOpenEmptyNotesNotification(true)
                                                                    }
                                                                })

                                                        }
                                                        }
                                                    >
                                                        <TableCell align="left" sx={{ padding: '2px 5px', color: '#000000', width: '80%', borderBottom: "none", wordWrap: "break-word", fontWeight: 540, fontSize: "0.8rem" }}>
                                                            Download Notes
                                                        </TableCell>
                                                        <TableCell align="left" sx={{ color: '#828282', width: '20%', padding: 0, borderBottom: "none" }}>

                                                            <IconButton sx={{ color: THEME_COLOR }} >
                                                                <TextSnippetIcon />
                                                            </IconButton>

                                                        </TableCell>

                                                    </TableRow>
                                                    {/* {props.slateRef && props.slateRef.current && <>
                                                        {removeDuplicates(props.slateRef.current.hostDetails).map((element) => {
                                                            return (
                                                                <TableRow sx={{
                                                                    maxWidth: '100%',
                                                                }}>
                                                                    <TableCell align="left" sx={{ padding: '2px 5px', color: '#000000', width: '80%', borderBottom: "none", wordWrap: "break-word", fontWeight: 540, fontSize: "0.8rem" }}>
                                                                        {element.firstName && element.lastName && element.firstName !== '' && element.lastName !== '' ? capitalizeFirstAlphabet(element.firstName) + ' ' + capitalizeFirstAlphabet(element.lastName) : element.email}'s Notes
                                                                    </TableCell>
                                                                    <TableCell align="left" sx={{ color: '#828282', width: '20%', padding: 0, borderBottom: "none" }}>

                                                                        <IconButton sx={{ color: THEME_COLOR }} onClick={() => {

                                                                            downloadItems("notes/" + element.user_id + "/", props.accessToken, props.whiteBoardID, props.slateRef, props.userName)
                                                                                .then(result => {
                                                                                    if (result === null) {
                                                                                        setOpenEmptyNotesNotification(true)
                                                                                    }
                                                                                })

                                                                        }
                                                                        }>
                                                                            <DownloadIcon />
                                                                        </IconButton>

                                                                    </TableCell>

                                                                </TableRow>
                                                            )
                                                        })
                                                        }
                                                        {removeDuplicates(props.slateRef.current.participantDetails).map((element) => {
                                                            return (
                                                                <TableRow sx={{
                                                                    maxWidth: '100%',
                                                                }}>
                                                                    <TableCell align="left" sx={{ padding: '2px 5px', color: '#000000', width: '80%', borderBottom: "none", wordWrap: "break-word", fontWeight: 540, fontSize: "0.8rem" }}>
                                                                        {element.firstName && element.lastName && element.firstName !== '' && element.lastName !== '' ? capitalizeFirstAlphabet(element.firstName) + ' ' + capitalizeFirstAlphabet(element.lastName) : element.email}'s Notes
                                                                    </TableCell>
                                                                    <TableCell align="left" sx={{ color: '#828282', width: '20%', padding: 0, borderBottom: "none" }}>

                                                                        <IconButton sx={{ color: THEME_COLOR }} onClick={() => {

                                                                            downloadItems("notes/" + element.user_id + "/", props.accessToken, props.whiteBoardID, props.slateRef, props.userName)
                                                                                .then(result => {
                                                                                    if (result === null) {
                                                                                        setOpenEmptyNotesNotification(true)
                                                                                    }
                                                                                })

                                                                        }
                                                                        }>
                                                                            <DownloadIcon />
                                                                        </IconButton>

                                                                    </TableCell>

                                                                </TableRow>
                                                            )
                                                        })
                                                        }
                                                        {!(findObjectById(props.slateRef.current.hostDetails, props.slateRef.current.creator_id) || findObjectById(props.slateRef.current.participantDetails, props.slateRef.current.creator_id)) &&
                                                            <TableRow sx={{
                                                                maxWidth: '100%',
                                                            }}>
                                                                <TableCell align="left" sx={{ padding: '2px 5px', color: '#000000', width: '80%', borderBottom: "none", wordWrap: "break-word", fontWeight: 540, fontSize: "0.8rem" }}>
                                                                    {props.slateRef.current.creatorDetails.firstName && props.slateRef.current.creatorDetails.lastName && props.slateRef.current.creatorDetails.firstName !== '' && props.slateRef.current.creatorDetails.lastName !== '' ? capitalizeFirstAlphabet(props.slateRef.current.creatorDetails.firstName) + ' ' + capitalizeFirstAlphabet(props.slateRef.current.creatorDetails.lastName) : props.slateRef.current.creatorDetails.email}'s Notes
                                                                </TableCell>
                                                                <TableCell align="left" sx={{ color: '#828282', width: '20%', padding: 0, borderBottom: "none" }}>

                                                                    <IconButton sx={{ color: THEME_COLOR }} onClick={() => {

                                                                        downloadItems("notes/" + props.slateRef.current.creatorDetails.user_id + "/", props.accessToken, props.whiteBoardID, props.slateRef, props.userName)
                                                                            .then(result => {
                                                                                if (result === null) {
                                                                                    setOpenEmptyNotesNotification(true)
                                                                                }
                                                                            })

                                                                    }
                                                                    }>
                                                                        <DownloadIcon />
                                                                    </IconButton>

                                                                </TableCell>
                                                            </TableRow>
                                                        }
                                                    </>} */}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </Box>
                                </Popover>


                                {<>
                                    {/* <Tooltip title={"Download Notes"}>
                                    <IconButton

                                        onClick={(e) => {
                                            setOpenDownloadNotesBox(e.currentTarget)
                                        }
                                        }

                                        size="medium">
                                        <NotesIcon sx={{ color: '#000000', backgroundColor: iconColor, borderRadius: '5px' }} />
                                    </IconButton>
                                </Tooltip> */}
                                    <Popover
                                        open={openDownloadNotesBox}
                                        anchorEl={openDownloadNotesBox}
                                        onClose={() => setOpenDownloadNotesBox(false)}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <Box sx={{ position: 'relative', overscrollBehavior: "none", maxHeight: '100%', maxWidth: '250px', minHeight: '25px' }} >
                                            <Grid container sx={{ color: 'black', height: '20px', position: 'sticky', right: 0, top: 0, zIndex: 10000, width: '100%' }} justifyContent='right' textAlign='right'>

                                                <IconButton
                                                    sx={{ color: 'black', position: 'sticky', zIndex: 10000 }}
                                                    onClick={() => {
                                                        setOpenDownloadNotesBox(false)
                                                    }}
                                                    size="small">
                                                    <CloseIcon sx={{ fontSize: '1rem' }} />
                                                    {/* <CloseIcon sx={{ fontSize: '1rem' }} /> */}
                                                </IconButton>

                                            </Grid>
                                            <Box sx={{ padding: '10px 15px' }}>

                                                <Table sx={{ tableLayout: "fixed" }} >

                                                    <TableBody >
                                                        {props.slateRef && props.slateRef.current && <>
                                                            {removeDuplicates(props.slateRef.current.hostDetails).map((element, index) => {
                                                                return (
                                                                    <TableRow
                                                                        key={index}
                                                                        sx={{
                                                                            maxWidth: '100%',
                                                                        }}>
                                                                        <TableCell align="left" sx={{ padding: '2px 5px', color: '#000000', width: '80%', borderBottom: "none", wordWrap: "break-word", fontWeight: 540, fontSize: "0.8rem" }}>
                                                                            {element.firstName && element.lastName && element.firstName !== '' && element.lastName !== '' ? capitalizeFirstAlphabet(element.firstName) + ' ' + capitalizeFirstAlphabet(element.lastName) : element.email}'s Notes
                                                                        </TableCell>
                                                                        <TableCell align="left" sx={{ color: '#828282', width: '20%', padding: 0, borderBottom: "none" }}>

                                                                            <IconButton sx={{ color: THEME_COLOR }} onClick={() => {

                                                                                downloadItems("notes/" + element.user_id + "/", props.accessToken, props.whiteBoardID, props.slateRef, props.userName)
                                                                                    .then(result => {
                                                                                        if (result === null) {
                                                                                            setOpenEmptyNotesNotification(true)
                                                                                        }
                                                                                    })

                                                                            }
                                                                            }>
                                                                                <DownloadIcon />
                                                                            </IconButton>

                                                                        </TableCell>

                                                                    </TableRow>
                                                                )
                                                            })
                                                            }
                                                            {removeDuplicates(props.slateRef.current.participantDetails).map((element, index) => {
                                                                return (
                                                                    <TableRow
                                                                        key={index}
                                                                        sx={{
                                                                            maxWidth: '100%',
                                                                        }}>
                                                                        <TableCell align="left" sx={{ padding: '2px 5px', color: '#000000', width: '80%', borderBottom: "none", wordWrap: "break-word", fontWeight: 540, fontSize: "0.8rem" }}>
                                                                            {element.firstName && element.lastName && element.firstName !== '' && element.lastName !== '' ? capitalizeFirstAlphabet(element.firstName) + ' ' + capitalizeFirstAlphabet(element.lastName) : element.email}'s Notes
                                                                        </TableCell>
                                                                        <TableCell align="left" sx={{ color: '#828282', width: '20%', padding: 0, borderBottom: "none" }}>

                                                                            <IconButton sx={{ color: THEME_COLOR }} onClick={() => {

                                                                                downloadItems("notes/" + element.user_id + "/", props.accessToken, props.whiteBoardID, props.slateRef, props.userName)
                                                                                    .then(result => {
                                                                                        if (result === null) {
                                                                                            setOpenEmptyNotesNotification(true)
                                                                                        }
                                                                                    })

                                                                            }
                                                                            }>
                                                                                <DownloadIcon />
                                                                            </IconButton>

                                                                        </TableCell>

                                                                    </TableRow>
                                                                )
                                                            })
                                                            }
                                                            {!(findObjectById(props.slateRef.current.hostDetails, props.slateRef.current.creator_id) || findObjectById(props.slateRef.current.participantDetails, props.slateRef.current.creator_id)) &&
                                                                <TableRow
                                                                    sx={{
                                                                        maxWidth: '100%',
                                                                    }}>
                                                                    <TableCell align="left" sx={{ padding: '2px 5px', color: '#000000', width: '80%', borderBottom: "none", wordWrap: "break-word", fontWeight: 540, fontSize: "0.8rem" }}>
                                                                        {props.slateRef.current.creatorDetails.firstName && props.slateRef.current.creatorDetails.lastName && props.slateRef.current.creatorDetails.firstName !== '' && props.slateRef.current.creatorDetails.lastName !== '' ? capitalizeFirstAlphabet(props.slateRef.current.creatorDetails.firstName) + ' ' + capitalizeFirstAlphabet(props.slateRef.current.creatorDetails.lastName) : props.slateRef.current.creatorDetails.email}'s Notes
                                                                    </TableCell>
                                                                    <TableCell align="left" sx={{ color: '#828282', width: '20%', padding: 0, borderBottom: "none" }}>

                                                                        <IconButton sx={{ color: THEME_COLOR }} onClick={() => {

                                                                            downloadItems("notes/" + props.slateRef.current.creatorDetails.user_id + "/", props.accessToken, props.whiteBoardID, props.slateRef, props.userName)
                                                                                .then(result => {
                                                                                    if (result === null) {
                                                                                        setOpenEmptyNotesNotification(true)
                                                                                    }
                                                                                })

                                                                        }
                                                                        }>
                                                                            <DownloadIcon />
                                                                        </IconButton>

                                                                    </TableCell>
                                                                </TableRow>
                                                            }
                                                        </>}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </Box>
                                    </Popover>
                                </>}
                                {props.isDeleteAllEnabled &&
                                    <Tooltip title="Clear whiteboard">
                                        <IconButton
                                            sx={{ color: iconColor }}
                                            onClick={() => {
                                                setClearAllBox(true)
                                            }
                                            }
                                            size="medium">
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                <Dialog
                                    open={clearAllBox}
                                    onClose={() => {
                                        setClearAllBox(false)
                                    }}
                                >
                                    <DialogTitle sx={{ justifyContent: 'center', textAlign: 'center', color: '#B33A3A' }} >
                                        Warning!
                                    </DialogTitle>
                                    <DialogContent>
                                        <Typography sx={{ fontSize: "1rem" }}>Are you sure you want to clear the whiteboard?</Typography>
                                    </DialogContent>
                                    <DialogActions>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Button
                                                sx={{ width: "50%", color: THEME_COLOR }}
                                                onClick={async () => {
                                                    setClearAllBox(false)
                                                    props._clear({ clientID: props.clientID })
                                                }}
                                            >
                                                Yes
                                            </Button>
                                            <Button
                                                sx={{ width: "50%", color: THEME_COLOR }}
                                                onClick={(e) => {
                                                    setClearAllBox(false)
                                                }}
                                            >
                                                No
                                            </Button>
                                        </Box>
                                    </DialogActions>
                                </Dialog>

                                {(isPanEnabled) && <Tooltip title="Recenter">

                                    <IconButton
                                        sx={{ color: iconColor }}
                                        onClick={() => {
                                            setRecenterBox(true)
                                        }}

                                        size={"small"}
                                    >
                                        <RotateLeftIcon />
                                    </IconButton>
                                </Tooltip>}
                                <Dialog
                                    open={recenterBox}
                                    onClose={() => {
                                        setRecenterBox(false)
                                    }}
                                >
                                    <DialogTitle sx={{ justifyContent: 'center', textAlign: 'center', color: '#B33A3A' }} >
                                        Warning!
                                    </DialogTitle>
                                    <DialogContent>
                                        <Typography sx={{ fontSize: "1rem" }}>Are you sure you want to recenter the whiteboard?</Typography>
                                    </DialogContent>
                                    <DialogActions>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Button
                                                sx={{ width: "50%", color: THEME_COLOR }}
                                                onClick={async () => {
                                                    setRecenterBox(false)
                                                    props._changePan({
                                                        clientID: props.clientID,
                                                        viewportTransform: [1, 0, 0, 1, 0, 0]
                                                    })
                                                    props.onNewZoomRestore()
                                                }}
                                            >
                                                Yes
                                            </Button>
                                            <Button
                                                sx={{ width: "50%", color: THEME_COLOR }}
                                                onClick={(e) => {
                                                    setRecenterBox(false)
                                                }}
                                            >
                                                No
                                            </Button>
                                        </Box>
                                    </DialogActions>
                                </Dialog>

                                <Tooltip title="Add link to whiteboard">
                                    <IconButton
                                        sx={{ color: iconColor }}
                                        onClick={() => {
                                            setOpenAsklink(true)
                                        }
                                        }
                                        size="medium">
                                        <OpenInNewIcon />
                                    </IconButton>
                                </Tooltip>
                                <Dialog
                                    open={openAsklink}
                                    onClose={() => {
                                        setOpenAsklink(false)
                                    }}
                                >
                                    <DialogTitle sx={{ justifyContent: 'center', textAlign: 'center', position: 'relative' }}>
                                        <Typography sx={{ fontSize: '1.25rem', fontWeight: '600', color: THEME_COLOR }}>
                                            Add link of any website to whiteboard
                                        </Typography>
                                        <Divider />
                                        <Typography sx={{ fontSize: '0.85rem', fontWeight: '400', margin: "15px 0 5px", maxWidth: '400px' }}>
                                            {/* Paste a link to be embedded into the canvas */}
                                        </Typography>
                                        <IconButton
                                            sx={{ color: 'black', position: 'absolute', right: '2.5px', top: "2.5px", zIndex: 10000 }}
                                            onClick={() => {
                                                setOpenAsklink(false)
                                                setIframeLink('')
                                            }}
                                            size="medium">
                                            <CloseIcon sx={{ fontSize: '0.95rem' }} />
                                        </IconButton>
                                    </DialogTitle>
                                    <DialogContent>
                                        <Grid container item xs={12} sm={12} md={12} lg={12} justifyContent='center' textAlign='center' sx={{ display: 'flex', flexDirection: 'column', minWidth: { xs: '300px', sm: '350px', md: '375px', lg: '375px' } }}>
                                            <form onSubmit={e => { e.preventDefault() }}>
                                                <Paper
                                                    component="form"
                                                    sx={{ boxShadow: 'none', borderRadius: '25px', p: '2px 4px', display: 'flex', alignItems: 'center', backgroundColor: '#f2f2f2' }}
                                                >
                                                    <IconButton type="button" sx={{ p: '10px' }} disabled size='small'>
                                                        <InsertLinkIcon sx={{ color: '#000000' }} />
                                                    </IconButton>
                                                    <InputBase
                                                        autoFocus={true}
                                                        sx={{ borderRadius: '10px', ml: 1, flex: 1, width: '100%', height: '50px', color: THEME_COLOR }}
                                                        placeholder="Enter Link"
                                                        value={iframeLink}
                                                        onChange={(e) => {
                                                            setIframeLink(e.target.value)
                                                        }}
                                                        onFocus={() => {
                                                            props.isTextFocused.current = true;
                                                        }}
                                                        onBlur={() => {
                                                            props.isTextFocused.current = false;
                                                        }}
                                                        onKeyDown={(e) => {
                                                            const { key, keyCode } = e;
                                                            // console.log(key, keyCode);
                                                            if (keyCode === 13) {
                                                                e.preventDefault();
                                                                setTimeout(() => {
                                                                    setOpenAsklink(false)
                                                                }, 200)
                                                                props._sketch.current.addIframeTag(iframeLink, false)
                                                                setIframeLink('')
                                                            }
                                                        }} />

                                                </Paper>
                                                <FormControl sx={{ width: '60%', marginBottom: '15px', marginTop: '15px', }}>
                                                    <Button
                                                        type='submit'
                                                        sx={{
                                                            borderRadius: '10px',
                                                            width: '100%',
                                                            height: '40px',
                                                            textTransform: 'none',
                                                            backgroundColor: THEME_COLOR,
                                                            color: '#FFFFFF',
                                                            fontSize: '1rem',
                                                            fontWeight: '500',
                                                            '&:hover': {
                                                                backgroundColor: THEME_HOVER_COLOR,
                                                                boxShadow: 'none',
                                                            },

                                                        }}
                                                        onClick={() => {
                                                            setOpenAsklink(false)
                                                            props._sketch.current.addIframeTag(iframeLink, false)
                                                            setIframeLink('')
                                                        }}
                                                    >
                                                        Add Link
                                                    </Button>
                                                </FormControl>
                                            </form>
                                        </Grid>
                                    </DialogContent>

                                </Dialog>
                                <Tooltip title="Add youtube video to whiteboard">
                                    <IconButton
                                        sx={{ color: 'red' }}
                                        onClick={() => {
                                            setOpenYoutubelink(true)
                                        }
                                        }
                                        size="medium">
                                        <YouTubeIcon />
                                    </IconButton>
                                </Tooltip>
                                <Dialog
                                    open={openYoutubelink}
                                    onClose={() => {
                                        setOpenYoutubelink(false)
                                    }}
                                >
                                    <DialogTitle sx={{ justifyContent: 'center', textAlign: 'center', position: 'relative' }}>
                                        <Typography sx={{ fontSize: '1.25rem', fontWeight: '600', color: THEME_COLOR }}>
                                            Add youtube video to whiteboard
                                        </Typography>
                                        <Divider />
                                        <Typography sx={{ fontSize: '0.85rem', fontWeight: '400', margin: "15px 0 5px", maxWidth: '400px' }}>
                                            {/* Paste a link to be embedded into the canvas */}
                                        </Typography>
                                        <IconButton
                                            sx={{ color: 'black', position: 'absolute', right: '5px', top: "5px", zIndex: 10000 }}
                                            onClick={() => {
                                                setOpenYoutubelink(false)
                                                setYoutubeLink('')
                                            }}
                                            size="medium">
                                            <CloseIcon sx={{ fontSize: '0.95rem' }} />
                                        </IconButton>
                                    </DialogTitle>
                                    <DialogContent>
                                        <Grid container item xs={12} sm={12} md={12} lg={12} justifyContent='center' textAlign='center' sx={{ display: 'flex', flexDirection: 'column', minWidth: { xs: '300px', sm: '350px', md: '375px', lg: '375px' } }}>
                                            <form onSubmit={e => { e.preventDefault() }}>
                                                <Paper
                                                    component="form"
                                                    sx={{ boxShadow: 'none', borderRadius: '25px', p: '2px 4px', display: 'flex', alignItems: 'center', backgroundColor: '#f2f2f2' }}
                                                >
                                                    <IconButton type="button" sx={{ p: '10px' }} disabled size='small'>
                                                        <InsertLinkIcon sx={{ color: '#000000' }} />
                                                    </IconButton>
                                                    <InputBase
                                                        autoFocus={true}
                                                        sx={{ borderRadius: '10px', ml: 1, flex: 1, width: '100%', height: '50px', color: THEME_COLOR }}
                                                        placeholder="Enter Link"
                                                        value={youtubeLink}
                                                        onChange={(e) => {
                                                            setYoutubeLink(e.target.value)
                                                        }}
                                                        onFocus={() => {
                                                            props.isTextFocused.current = true;
                                                        }}
                                                        onBlur={() => {
                                                            props.isTextFocused.current = false;
                                                        }}
                                                        onKeyDown={(e) => {
                                                            const { key, keyCode } = e;
                                                            // console.log(key, keyCode);
                                                            if (keyCode === 13) {
                                                                e.preventDefault();
                                                                setTimeout(() => {
                                                                    setOpenYoutubelink(false)
                                                                }, 200)
                                                                let id = getYoutubeLinkId(youtubeLink)
                                                                let embeddedUrl = "//www.youtube.com/embed/" + id
                                                                props._sketch.current.addIframeTag(embeddedUrl, false)
                                                                setYoutubeLink('')
                                                            }
                                                        }}
                                                    />

                                                </Paper>
                                                <FormControl sx={{ width: '60%', marginBottom: '15px', marginTop: '15px', }}>
                                                    <Button
                                                        type='submit'
                                                        sx={{
                                                            borderRadius: '10px',
                                                            width: '100%',
                                                            height: '40px',
                                                            textTransform: 'none',
                                                            backgroundColor: THEME_COLOR,
                                                            color: '#FFFFFF',
                                                            fontSize: '1rem',
                                                            fontWeight: '500',
                                                            '&:hover': {
                                                                backgroundColor: THEME_HOVER_COLOR,
                                                                boxShadow: 'none',
                                                            },

                                                        }}
                                                        onClick={() => {
                                                            setOpenYoutubelink(false)
                                                            let id = getYoutubeLinkId(youtubeLink)
                                                            let embeddedUrl = "//www.youtube.com/embed/" + id
                                                            props._sketch.current.addIframeTag(embeddedUrl, false)
                                                            setYoutubeLink('')
                                                        }}
                                                    >
                                                        Add youtube video
                                                    </Button>
                                                </FormControl>
                                            </form>
                                        </Grid>
                                    </DialogContent>

                                </Dialog>
                                {/* <Tooltip title="Open pdf viewer">
                                    <IconButton
                                        sx={{ color: iconColor }}
                                        onClick={() => {
                                            setOpenAskPdflink(true)
                                        }
                                        }
                                        size="medium">
                                        <PictureAsPdfIcon />
                                    </IconButton>
                                </Tooltip> */}
                                <Dialog
                                    open={openAskPdflink}
                                    onClose={() => {
                                        setOpenAskPdflink(false)
                                    }}
                                >
                                    <DialogTitle sx={{ justifyContent: 'center', textAlign: 'center', position: 'relative' }}>
                                        <Typography sx={{ fontSize: '1.25rem', fontWeight: '600', color: THEME_COLOR }}>
                                            Add Pdf to whiteboard
                                        </Typography>
                                        <Divider />
                                        <Typography sx={{ fontSize: '0.85rem', fontWeight: '400', margin: "15px 0 5px", maxWidth: '400px' }}>
                                            {/* Paste a link to be embedded into the canvas */}
                                        </Typography>
                                        <IconButton
                                            sx={{ color: 'black', position: 'absolute', right: '5px', top: "5px", zIndex: 10000 }}
                                            onClick={() => {
                                                setOpenAskPdflink(false)
                                                setPdfLink('')
                                            }}
                                            size="medium">
                                            <CloseIcon sx={{ fontSize: '0.95rem' }} />
                                        </IconButton>
                                    </DialogTitle>
                                    <DialogContent>
                                        <Grid container item xs={12} sm={12} md={12} lg={12} justifyContent='center' textAlign='center' sx={{ display: 'flex', flexDirection: 'column', minWidth: { xs: '300px', sm: '350px', md: '375px', lg: '375px' } }}>
                                            <form onSubmit={e => { e.preventDefault() }}>
                                                <Paper
                                                    component="form"
                                                    sx={{ boxShadow: 'none', borderRadius: '25px', p: '2px 4px', display: 'flex', alignItems: 'center', backgroundColor: '#f2f2f2' }}
                                                >
                                                    <IconButton type="button" sx={{ p: '10px' }} disabled size='small'>
                                                        <InsertLinkIcon sx={{ color: '#000000' }} />
                                                    </IconButton>
                                                    <InputBase
                                                        autoFocus={true}
                                                        sx={{ borderRadius: '10px', ml: 1, flex: 1, width: '100%', height: '50px', color: THEME_COLOR }}
                                                        placeholder="Enter Link"
                                                        value={pdfLink}
                                                        onChange={(e) => {
                                                            setPdfLink(e.target.value)
                                                        }}
                                                        onFocus={() => {
                                                            props.isTextFocused.current = true;
                                                        }}
                                                        onBlur={() => {
                                                            props.isTextFocused.current = false;
                                                        }}
                                                    />

                                                </Paper>
                                                <FormControl sx={{ width: '60%', marginBottom: '15px', marginTop: '15px', }}>
                                                    <Button
                                                        type='submit'
                                                        sx={{
                                                            borderRadius: '10px',
                                                            width: '100%',
                                                            height: '40px',
                                                            textTransform: 'none',
                                                            backgroundColor: THEME_COLOR,
                                                            color: '#FFFFFF',
                                                            fontSize: '1rem',
                                                            fontWeight: '500',
                                                            '&:hover': {
                                                                backgroundColor: THEME_HOVER_COLOR,
                                                                boxShadow: 'none',
                                                            },

                                                        }}
                                                        onClick={() => {
                                                            setOpenAskPdflink(false)
                                                            props._sketch.current.addIframeTag(pdfLink, 'pdfViewer')
                                                            setPdfLink('')
                                                        }}
                                                    >
                                                        Add Pdf to whiteboard
                                                    </Button>
                                                </FormControl>
                                            </form>
                                        </Grid>
                                    </DialogContent>

                                </Dialog>
                                {<>
                                    <Tooltip title={"Bookmark Page"}>
                                        <IconButton
                                            sx={{ color: iconColor }}
                                            onClick={(e) => {
                                                setOpenBookmarkBox(e.currentTarget)
                                            }
                                            }

                                            size="medium">
                                            <BookmarkIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Popover
                                        open={openBookmarkBox}
                                        anchorEl={openBookmarkBox}
                                        onClose={() => {
                                            setOpenBookmarkBox(false)
                                            setBookmarkPageNumber('')
                                        }}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <Box sx={{ position: 'relative', overscrollBehavior: "none", maxHeight: '100%', minHeight: '25px' }} >
                                            <Grid container sx={{ color: 'black', height: '15px', position: 'sticky', right: 0, top: 0, zIndex: 10000, width: '100%' }} justifyContent='right' textAlign='right'>

                                                <IconButton
                                                    sx={{ color: 'black', position: 'sticky', zIndex: 10000 }}
                                                    onClick={() => {
                                                        setOpenBookmarkBox(false)
                                                        setBookmarkPageNumber('')
                                                    }}
                                                    size="small">
                                                    <CloseIcon sx={{ fontSize: '1rem' }} />
                                                    {/* <CloseIcon sx={{ fontSize: '1rem' }} /> */}
                                                </IconButton>

                                            </Grid>
                                            <Box sx={{ padding: '5px 40px 15px' }}>
                                                <Grid container item xs={12} sm={12} md={12} lg={12} justifyContent='center' textAlign='center' sx={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: '5px' }}>
                                                    <Grid container justifyContent='center' textAlign='center'>

                                                        <Button sx={{
                                                            textTransform: 'none',
                                                            backgroundColor: THEME_COLOR,
                                                            color: '#FFFFFF',
                                                            marginTop: '2px',
                                                            borderRadius: '10px',
                                                            padding: '10px 15px',
                                                            '&:hover': {
                                                                backgroundColor: THEME_HOVER_COLOR,
                                                                boxShadow: 'none',
                                                            },
                                                        }}
                                                            onClick={() => {
                                                                if (props._sketch.current && props._sketch.current._fc.current) {
                                                                    const canvas = props._sketch.current._fc.current;
                                                                    let viewportTransform = canvas.viewportTransform;
                                                                    const screenHeight = canvas.getHeight();
                                                                    let bookmarkPageNo = Math.ceil(Math.abs(viewportTransform[5]) / screenHeight) + 1
                                                                    let zoomLevel = 1
                                                                    if (props._sketch.current) {
                                                                        zoomLevel = props._sketch.current.state.zoomFactors
                                                                    }
                                                                    const bookmarkObj = {
                                                                        'bookmarkNumber': bookmarksList.length + 1,
                                                                        'bookmarkViewPort': viewportTransform,
                                                                        'zoomLevel': zoomLevel
                                                                    }
                                                                    // console.log(bookmarkPageNo)
                                                                    if (!bookmarksList.includes(bookmarkPageNo)) {
                                                                        setBookmarksList(prevValue => {
                                                                            return [...prevValue, bookmarkObj]
                                                                        })
                                                                        const jsonList = JSON.stringify([...bookmarksList, bookmarkObj])
                                                                        setCookie('PAGE_NUMBER_BOOKMARK', jsonList, 1 / 24)
                                                                    }
                                                                }
                                                            }
                                                            }
                                                            size='small'>
                                                            Bookmark this page <BookmarkIcon sx={{ fontSize: '1rem' }} />
                                                        </Button>

                                                    </Grid>
                                                </Grid>
                                                <Grid container item xs={12} sm={12} md={12} lg={12} justifyContent='center' textAlign='center' sx={{ width: '100%', display: 'flex', flexDirection: 'column', marginTop: '5px' }}>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} >
                                                        <Typography sx={{ fontWeight: 600, fontSize: '1.1rem', padding: "5px 0", color: THEME_COLOR }}>
                                                            Bookmarks List
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} justifyContent='center' textAlign='center' sx={{ display: 'inline-flex', flexDirection: 'column' }}>
                                                        {bookmarksList.length === 0 ?
                                                            <Typography sx={{ fontWeight: 500, fontSize: '0.75rem', textTransform: 'none', color: 'black' }}>
                                                                No bookmarks yet
                                                            </Typography>
                                                            :
                                                            bookmarksList.map((element) => {
                                                                return <>
                                                                    <Grid item xs={12} sm={12} md={12} lg={12} justifyContent='center' textAlign='center' >
                                                                        <Button
                                                                            sx={{
                                                                                fontWeight: 500,
                                                                                fontSize: '0.95rem',
                                                                                textTransform: 'none',
                                                                                color: "#718096",
                                                                                borderRadius: '10px',
                                                                                width: 'auto',
                                                                                '&:hover': {
                                                                                    backgroundColor: '#141416',
                                                                                    color: '#ffffff'
                                                                                },
                                                                            }}
                                                                            onClick={() => {
                                                                                // console.log(element)
                                                                                let { offsetWidth = 10, clientHeight = 10 } = props._sketch.current._container;
                                                                                props._changePan({
                                                                                    clientID: props.clientID,
                                                                                    viewportTransform: element.bookmarkViewPort,
                                                                                    containerHeight: clientHeight,
                                                                                    containerWidth: offsetWidth,
                                                                                })
                                                                                props.onNewZoom(1);
                                                                                // props.panByPageNumber(element)
                                                                            }}
                                                                        >
                                                                            Bookmark {element.bookmarkNumber}
                                                                        </Button>
                                                                    </Grid>
                                                                </>
                                                            })
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Popover>
                                </>
                                }
                                {/* {(isPanEnabled) && <Tooltip title={"Go to last page"}>
                                    <IconButton
                                        sx={{ color: iconColor }}
                                        onClick={() => {
                                            props.panToLastPage()
                                        }
                                        }

                                        size="medium">
                                        <ExitToAppIcon />
                                    </IconButton>
                                </Tooltip>} */}
                                {/* {props.isTeacher && <Tooltip title="Toggle toolbar">

                                    <IconButton
                                        sx={{ color: iconColor }}
                                        onClick={async (event) => {

                                            props.sendLessonEventViaWebSocket({
                                                eventType: 'sessionStateUpdated',
                                                eventData: {
                                                    'sentBy': props.clientID,
                                                    'sessionState': window.sessionState
                                                }
                                            })
                                            let sessionStateData = window.sessionState
                                            let status, res;
                                            try {
                                                res = await updateSessionState(props.accessToken, props.whiteBoardID, JSON.stringify(sessionStateData), props.slateRef.current.id);
                                                status = res.status
                                            }
                                            catch (err) {
                                                console.log(err);
                                                status = err.response.status;
                                            }
                                            // console.log(index)
                                            if (status === 200) {
                                            }
                                            else {
                                                console.log(status)
                                            }
                                        }

                                        }
                                        size={"small"}
                                    >
                                        <EditOffIcon />
                                    </IconButton>
                                </Tooltip>} */}
                                <Popover
                                    open={openToggledToolbar}
                                    anchorEl={openToggledToolbar}
                                    onClose={() => {
                                        setOpenToggledToolbar(false)
                                    }}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Box sx={{ backgroundColor: "#525252", boxShadow: 'none' }}>
                                        <Typography sx={{ fontSize: '0.8rem', color: '#FFFFFF', padding: '5px', fontWeight: 500 }}>
                                            {"Toolbar " + (toolBarButtonState ? "Enabled" : "Disabled")}
                                        </Typography>
                                    </Box>
                                </Popover>

                                {/* {props.isTeacher && <Tooltip title="Toggle Audio/Video">

                                    <IconButton
                                        sx={{ color: iconColor }}
                                        onClick={(event) => {
                                            if (audioVideoState) {
                                                props.sendLessonEventViaWebSocket({
                                                    eventType: 'toggleVideo',
                                                    eventData: {
                                                        'sentBy': props.clientID,
                                                        'cameraState': false
                                                    }
                                                })
                                                props.sendLessonEventViaWebSocket({
                                                    eventType: 'toggleAudio',
                                                    eventData: {
                                                        'sentBy': props.clientID,
                                                        'micState': false
                                                    }
                                                })
                                                setAudioVideoState(false)
                                            }
                                            else {
                                                props.sendLessonEventViaWebSocket({
                                                    eventType: 'toggleVideo',
                                                    eventData: {
                                                        'sentBy': props.clientID,
                                                        'cameraState': true
                                                    }
                                                })
                                                props.sendLessonEventViaWebSocket({
                                                    eventType: 'toggleAudio',
                                                    eventData: {
                                                        'sentBy': props.clientID,
                                                        'micState': true
                                                    }
                                                })
                                                setAudioVideoState(true)
                                            }

                                        }}
                                        size={"small"}
                                    >
                                        <PermCameraMicIcon />
                                    </IconButton>
                                </Tooltip>} */}
                                <Popover
                                    open={openToggledPan}
                                    anchorEl={openToggledPan}
                                    onClose={() => {
                                        setOpenToggledPan(false)
                                    }}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Box sx={{ backgroundColor: "#525252", boxShadow: 'none' }}>
                                        <Typography sx={{ fontSize: '0.8rem', color: '#FFFFFF', padding: '5px', fontWeight: 500 }}>
                                            {"Pan " + (togglePanButtonState ? "Enabled" : "Disabled")}
                                        </Typography>
                                    </Box>
                                </Popover>
                                <Tooltip title="Choose whiteboard background">
                                    <Button
                                        id='background-button-whiteboard'
                                        sx={{ color: iconColor }}
                                        aria-describedby={"backgroundColorPicker"}
                                        onClick={(event) => {
                                            setOpenBackgroundColorPicker(event.currentTarget)
                                        }
                                        }

                                    >
                                        <FiberManualRecordIcon sx={{
                                            color: backgroundColor,
                                            backgroundColor: iconColor,
                                            borderRadius: '5px'
                                        }} />
                                        &nbsp; BG
                                    </Button>
                                </Tooltip>

                                {<>
                                    <Tooltip title={"Go To Page"}>
                                        <Box sx={{ display: "flex", flexDirection: 'row', paddingTop: "2.5px", borderRadius: '10px', justifyContent: "center", textAlign: 'center', alignItems: 'center' }}>
                                            {(isPanEnabled) ? <TextField
                                                type="number"
                                                value={pageNumber}
                                                sx={{
                                                    input: {
                                                        color: "#ffffff",
                                                        width: '80px',
                                                        height: '2px',
                                                        borderColor: '#ffffff !important',
                                                        fontSize: '0.9rem',
                                                        padding: '10px',
                                                        justifyContent: 'center',
                                                        textAlign: 'center',
                                                        backgroundColor: WHITEBOARD_TOOLBAR_COLOR,
                                                    },

                                                    borderRadius: '5px',
                                                    width: pageNumber > 9 ? pageNumber > 99 ? pageNumber > 999 ? '60px' : '50px' : '40px' : '30px',
                                                    height: '2px',
                                                    color: '#ffffff',
                                                    padding: 0,
                                                    marginBottom: '20px',
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: 'white',

                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: 'white',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            border: "2px solid",
                                                            borderColor: 'white',
                                                            borderRadius: '5px',
                                                        },
                                                    },
                                                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                                                        'WebkitAppearance': 'none',
                                                        margin: 0,
                                                    },
                                                    '& input[type="number"]': {
                                                        'MozAppearance': 'textfield',
                                                    },
                                                }}
                                                onFocus={() => {
                                                    props.isTextFocused.current = true;
                                                }}
                                                onBlur={() => {
                                                    props.isTextFocused.current = false;
                                                    // console.log(pageNumber)
                                                    if (!pageNumber) {
                                                        props.handlePageNumber(1)
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    props.handlePageNumber(e.target.value)
                                                    // console.log(e.target.value)
                                                    if (e.target.value > totalNumberOfPages) {
                                                        props.handleTotalNumberOfPages(e.target.value)
                                                    }
                                                    if (e.target.value && e.target.value != 0) {
                                                        props.panByPageNumber(e.target.value)
                                                    }
                                                    else {
                                                        // props.panByPageNumber(1)
                                                    }

                                                }}
                                                autoFocus /> :
                                                <span style={{ color: '#9F9EB2', fontSize: '1rem', fontWeight: '550' }} >
                                                    {pageNumber}
                                                </span>
                                            }
                                            &nbsp;
                                            <span style={{ color: '#9F9EB2', fontSize: '0.9rem', paddingTop: '0.1rem' }}>
                                                of
                                            </span>
                                            &nbsp;
                                            <Button sx={{
                                                cursor: 'pointer',
                                                color: '#9F9EB2',
                                                fontSize: '1rem',
                                                fontWeight: '550',
                                                padding: '2px',
                                                minWidth: 0
                                            }}
                                                onClick={() => {
                                                    props.panByPageNumber(totalNumberOfPages)
                                                }}
                                            >
                                                {totalNumberOfPages}
                                            </Button>
                                            &nbsp;
                                        </Box>
                                    </Tooltip>

                                </>}
                            </>}



                            <Popover
                                open={openLineWidthPicker}
                                anchorEl={openLineWidthPicker}
                                onClose={() => setOpenLineWidthPicker(false)}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                            >
                                <Box sx={{ background: THEME_COLOR }}>
                                    <Box sx={{ justifyContent: 'center', textAlign: "center", maxHeight: '10px', color: lineColor === '#FFFFFF' ? 'white' : 'black' }}>
                                        <label htmlFor="lineWidth" ><b>Line Width</b></label>
                                    </Box>
                                    <br />

                                    {[1, 3, 6, 9, 12].map((val, index) =>
                                        <Button
                                            onClick={(e) => {
                                                // console.log("selected width", e.currentTarget.value)
                                                //added + to make it a number instead of string
                                                setOpenLineWidthPicker(false)
                                                handleLineWidth(+e.currentTarget.value)
                                            }}
                                            value={val}
                                            key={index}
                                        >
                                            <Box sx={{ transform: "rotate(45deg)", borderRadius: '30%', background: lineColor, height: '25px', width: val + 'px' }}></Box>
                                        </Button>
                                    )}
                                </Box>
                            </Popover>

                            <Popover
                                open={openBackgroundColorPicker}
                                anchorEl={openBackgroundColorPicker}
                                onClose={() => setOpenBackgroundColorPicker(false)}
                                anchorOrigin={{
                                    vertical: -20,
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                            >
                                <Box sx={{ justifyContent: 'center', textAlign: "center", padding: '20px' }}>
                                    <Box sx={{ justifyContent: 'center', textAlign: "center", padding: '0 0 10px' }}>
                                        <Box sx={{ justifyContent: 'center', textAlign: "center", marginBottom: '10px' }}>
                                            <b>Background Pattern</b>
                                        </Box>

                                        <Tooltip title='Dotted grid' placement='top'>
                                            <IconButton
                                                sx={{
                                                    background: 'dotted-grid' === backgroundType ? 'rgb(187, 216, 255)' : 'transparent',
                                                    borderRadius: '10%', height: '30px', width: '30px', marginRight: '10px'
                                                }}
                                                onClick={(e) => {
                                                    props._changeBackgroundColor({
                                                        color: backgroundColor,
                                                        clientID: props.clientID,
                                                        backgroundPattern: true,
                                                        patternType: 'dotted-grid',
                                                        patternColor: patternColor
                                                    })
                                                    setOpenBackgroundColorPicker(false)
                                                }}
                                            >
                                                <ViewCompactIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='Grid' placement='top'>
                                            <IconButton
                                                sx={{
                                                    background: 'grid' === backgroundType ? 'rgb(187, 216, 255)' : 'transparent',
                                                    borderRadius: '10%', height: '30px', width: '30px', marginRight: '10px'
                                                }}
                                                onClick={(e) => {
                                                    props._changeBackgroundColor({
                                                        color: backgroundColor,
                                                        clientID: props.clientID,
                                                        backgroundPattern: true,
                                                        patternType: 'grid',
                                                        patternColor: patternColor
                                                    })
                                                    setOpenBackgroundColorPicker(false)
                                                }}
                                            >
                                                <Grid4x4Icon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='Horizontal lines' placement='top'>
                                            <IconButton
                                                sx={{
                                                    background: 'horizontal-lines' === backgroundType ? 'rgb(187, 216, 255)' : 'transparent',
                                                    borderRadius: '10%', height: '30px', width: '30px', marginRight: '10px'
                                                }}
                                                onClick={(e) => {
                                                    props._changeBackgroundColor({
                                                        color: backgroundColor,
                                                        clientID: props.clientID,
                                                        backgroundPattern: true,
                                                        patternType: 'horizontal-lines',
                                                        patternColor: patternColor
                                                    })
                                                    setOpenBackgroundColorPicker(false)
                                                }}
                                            >
                                                <DensitySmallIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='None' placement='top'>
                                            <IconButton
                                                sx={{
                                                    background: 'None' === backgroundType ? 'rgb(187, 216, 255)' : 'transparent',
                                                    borderRadius: '10%', height: '30px', width: '30px'
                                                }}
                                                onClick={(e) => {
                                                    props._changeBackgroundColor({
                                                        color: backgroundColor,
                                                        clientID: props.clientID,
                                                        backgroundPattern: true,
                                                        patternType: 'None',
                                                        patternColor: patternColor
                                                    })
                                                    setOpenBackgroundColorPicker(false)
                                                }}
                                            >
                                                <DoNotDisturbIcon />
                                            </IconButton>
                                        </Tooltip>

                                    </Box>
                                    <Box sx={{ justifyContent: 'center', textAlign: "center", marginBottom: '15px', marginTop: '10px' }}>
                                        <label htmlFor="patternColorPicker" ><b>Pattern Color</b></label>
                                    </Box>
                                    <TwitterPicker
                                        sx={{ width: '100%' }}
                                        colors={colors}
                                        id="patternColorPicker"
                                        color={patternColor}
                                        onChange={(color) => {
                                            props._changeBackgroundColor({
                                                color: backgroundColor,
                                                clientID: props.clientID,
                                                backgroundPattern: true,
                                                patternType: backgroundType,
                                                patternColor: color.hex
                                            })
                                            setOpenBackgroundColorPicker(false)
                                        }}
                                    />

                                    <Box sx={{ justifyContent: 'center', textAlign: "center", maxHeight: '10px', marginBottom: '5px', marginTop: '10px' }}>
                                        <label htmlFor="backgroundColorPicker" ><b>Background Color</b></label>
                                    </Box>
                                    <br />
                                    <TwitterPicker
                                        sx={{ width: '100%' }}
                                        colors={colors}
                                        id="backgroundColorPicker"
                                        color={backgroundColor}
                                        onChange={(color) => {
                                            props._changeBackgroundColor({
                                                color: color.hex,
                                                clientID: props.clientID,
                                                backgroundPattern: true,
                                                patternType: backgroundType,
                                                patternColor: patternColor
                                            })
                                            setOpenBackgroundColorPicker(false)
                                            props.handleBackgroundColor(color.hex)

                                        }}
                                    />
                                </Box>
                            </Popover>
                            <Box sx={{ zIndex: 90000000, border: 1, backgroundColor: "black", position: "absolute" }}>
                                <Popover
                                    open={openLineColorPicker}
                                    anchorEl={openLineColorPicker}
                                    onClose={() => setOpenLineColorPicker(false)}
                                    anchorOrigin={{
                                        vertical: -20,
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}

                                >
                                    <Grid container sx={{ color: 'black', height: '20px', position: 'sticky', right: 0, top: 0, zIndex: 10000, width: '100%' }} justifyContent='right' textAlign='right'>

                                        <IconButton
                                            sx={{ color: 'black', position: 'sticky', zIndex: 10000 }}
                                            onClick={() => {
                                                setOpenLineColorPicker(false)
                                            }}
                                            size="small">
                                            <CloseIcon sx={{ fontSize: '1rem' }} />
                                            {/* <CloseIcon sx={{ fontSize: '1rem' }} /> */}
                                        </IconButton>

                                    </Grid>
                                    <Box sx={{ justifyContent: 'center', textAlign: "center", padding: '20px' }}>

                                        <Box sx={{ justifyContent: 'center', textAlign: "center", maxHeight: '10px' }}>
                                            <label htmlFor="lineColor" ><b>Drawing Color</b></label>
                                        </Box>
                                        <br />

                                        <TwitterPicker
                                            colors={lineColors}
                                            id="lineColor"
                                            color={lineColor}
                                            onChange={(color) => {
                                                // setOpenLineColorPicker(false)
                                                handleLineColor(color.hex)
                                            }}
                                        />

                                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: "center", marginTop: '10px', marginBottom: "10px" }}>
                                            <Box sx={{ justifyContent: 'center', textAlign: "center", maxHeight: '10px', color: 'black' }}>
                                                <label htmlFor="lineWidth" ><b>Line Width</b></label>
                                            </Box>
                                            <br />
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: "center" }}>


                                                {[3, 6, 9].map((val, index) =>
                                                    <Button
                                                        onClick={(e) => {
                                                            // console.log("selected width", e.currentTarget.value)
                                                            //added + to make it a number instead of string
                                                            // setOpenLineWidthPicker(false)
                                                            // setOpenLineColorPicker(false)
                                                            handleLineWidth(+e.currentTarget.value)
                                                        }}
                                                        sx={{
                                                            background: val === lineWidth ? 'rgb(187, 216, 255)' : 'transparent',
                                                            minWidth: '35px',
                                                            padding: '5px'
                                                        }}
                                                        value={val}
                                                        key={index}
                                                    >
                                                        <Box sx={{ transform: "rotate(45deg)", borderRadius: '30%', background: 'grey', height: '25px', width: val + 'px' }}></Box>
                                                    </Button>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: "center", marginTop: '10px', marginBottom: "10px" }}>
                                            <Box sx={{ justifyContent: 'center', textAlign: "center", maxHeight: '10px', color: 'black' }}>
                                                <label htmlFor="mousePointer" ><b>Cursor Type</b></label>
                                            </Box>
                                            <br />
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: "center" }}>


                                                <Button
                                                    onClick={(e) => {
                                                        setPointerType('pencil')
                                                    }}
                                                    sx={{
                                                        background: pointerType === 'pencil' ? 'rgb(187, 216, 255)' : 'transparent',
                                                        minWidth: '35px',
                                                        padding: '5px'
                                                    }}
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        width="24"
                                                        height="24"
                                                        fill="none"
                                                        stroke={lineColor}
                                                        stroke-width="2"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round">
                                                        <path d="M18.5 3.5l2 2-12 12-4 1 1-4 12-12z" />
                                                    </svg>
                                                </Button>
                                                <Button
                                                    onClick={(e) => {
                                                        setPointerType('circle')
                                                    }}
                                                    sx={{
                                                        background: pointerType === 'circle' ? 'rgb(187, 216, 255)' : 'transparent',
                                                        minWidth: '35px',
                                                        padding: '5px'
                                                    }}

                                                >
                                                    <svg
                                                        height={16}
                                                        fill={lineColor}
                                                        viewBox="0 0 24 24}"
                                                        width={16}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <circle
                                                            cx="50%"
                                                            cy="50%"
                                                            r={8}
                                                        />
                                                    </svg>
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Popover>
                                <Popover
                                    open={openHighlighterColorPicker}
                                    anchorEl={openHighlighterColorPicker}
                                    onClose={() => setOpenHighlighterColorPicker(false)}
                                    anchorOrigin={{
                                        vertical: -20,
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Grid container sx={{ color: 'black', height: '20px', position: 'sticky', right: 0, top: 0, zIndex: 10000, width: '100%' }} justifyContent='right' textAlign='right'>

                                        <IconButton
                                            sx={{ color: 'black', position: 'sticky', zIndex: 10000 }}
                                            onClick={() => {
                                                setOpenHighlighterColorPicker(false)
                                            }}
                                            size="small">
                                            <CloseIcon sx={{ fontSize: '1rem' }} />
                                            {/* <CloseIcon sx={{ fontSize: '1rem' }} /> */}
                                        </IconButton>

                                    </Grid>
                                    <Box sx={{ justifyContent: 'center', textAlign: "center", padding: '20px' }}>
                                        <Box sx={{ justifyContent: 'center', textAlign: "center", maxHeight: '10px' }}>
                                            <label htmlFor="HighlighterColor" ><b>Highlighter Color</b></label>
                                        </Box>
                                        <br />

                                        <TwitterPicker
                                            colors={highlighterColors}
                                            id="HighlighterColor"
                                            color={highlighterColor}
                                            onChange={(color) => {
                                                // setOpenHighlighterColorPicker(false)
                                                handleHighlighterColor(color.hex)
                                            }}
                                        />

                                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: "center", marginTop: '10px', marginBottom: "10px" }}>
                                            <Box sx={{ justifyContent: 'center', textAlign: "center", maxHeight: '10px', color: 'black' }}>
                                                <label htmlFor="HighlighterWidth" ><b>Line Width</b></label>
                                            </Box>
                                            <br />
                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: "center" }}>


                                                {[6, 9, 12].map((val, index) =>
                                                    <Button
                                                        onClick={(e) => {
                                                            // setOpenHighlighterColorPicker(false)
                                                            handleHighlighterWidth(+e.currentTarget.value)
                                                        }}
                                                        sx={{
                                                            background: val === highlighterWidth ? 'rgb(187, 216, 255)' : 'transparent',
                                                            minWidth: '35px',
                                                            padding: '5px'
                                                        }}
                                                        value={val}
                                                        key={index}
                                                    >
                                                        <Box sx={{ transform: "rotate(45deg)", borderRadius: '30%', background: 'grey', height: '25px', width: val + 'px' }}></Box>
                                                    </Button>
                                                )}
                                            </Box>
                                        </Box>
                                    </Box>
                                </Popover>
                            </Box>




                        </>
                    }
                </Box>
            </Box>
            <Box sx={{ position: 'fixed', zIndex: 10 }}>{
                <Drawer
                    sx={{

                        flexShrink: 0,
                        zIndex: 2,
                        '& .MuiDrawer-paper': {
                            width: '100%',
                            height: '70px',
                            background: '#ffffff',
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '2px 10px 2px',
                            boxShadow: 1,
                            zIndex: 2
                        },
                    }}
                    variant="persistent"
                    anchor="top"
                    open={showTopBar}
                >


                    <Box sx={{
                        width: '30%',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: { xs: '10px 2.5px 2px', sm: '2px 2.5px 2px', md: '2px 2.5px 2px', lg: '2px 2.5px 2px' },
                        justifyContent: "left",
                        textAlign: 'left'
                    }}>

                        {(props.slateRef && props.slateRef.current && props.slateRef.current.creator_id === props.clientID) && showInputElement ? (

                            <OutlinedInput
                                id='myField'
                                sx={{
                                    borderRadius: '5px',
                                    marginBottom: '5px',
                                    marginTop: '5px',
                                    height: { xs: "20px", sm: '30px', md: "30px", lg: '30px', xl: '30px' },
                                    fontSize: { xs: "0.75rem", sm: '1.2rem', md: "1.25rem", lg: '1.25rem', xl: '1.25rem' },
                                    fontWeight: 600,
                                    '&.Mui-focused fieldset': {
                                        borderColor: `${THEME_COLOR} !important`,

                                    },
                                }}
                                type="text"
                                value={slateTitle}
                                onChange={(e) => {
                                    setSlateTitle(e.target.value)
                                    setSlateTitleChanged(true)
                                }}
                                onFocus={() => {
                                    props.isTextFocused.current = true
                                }}
                                onBlur={handleBlur}
                                onKeyDown={checkKeyPress}
                                autoFocus />

                        ) : (
                            <Box sx={{
                                marginTop: '5px',
                                marginBottom: '5px',
                                height: { xs: "20px", sm: '30px', md: "30px", lg: '30px', xl: '30px' },
                                justifyContent: 'left',
                                textAlign: 'left',
                                alignItems: 'left'
                            }}>
                                <span
                                    onClick={() => {
                                        setShowInputElement(true)

                                    }}
                                    style={{
                                        display: "inline-block",
                                        cursor: 'pointer',
                                        minWidth: '30px'
                                    }}
                                >
                                    <Typography sx={{
                                        fontSize: { xs: "0.75rem", sm: '1.2rem', md: "1.25rem", lg: '1.25rem', xl: '1.25rem' },
                                        fontWeight: 600,
                                    }}>
                                        {slateTitle.substring(0, 18)}{slateTitle.length > 18 && '...'}
                                    </Typography>

                                </span>
                            </Box>
                        )
                        }
                        {(props.slateRef && props.slateRef.current) &&
                            <Box sx={{
                                justifyContent: 'left',
                                textAlign: 'left',
                                height: { xs: "15px", sm: '20px', md: "20px", lg: '20px', xl: '20px' },
                                alignItems: 'left',
                                display: { xs: "none", sm: 'flex', md: 'flex', lg: 'flex' }
                            }}>
                                <Typography sx={{ fontSize: { xs: "0.65rem", sm: '1rem', md: "1rem", lg: '1rem', xl: '1rem' }, fontWeight: 550 }}>
                                    Creator:  {props.slateRef.current.creatorDetails.firstName && props.slateRef.current.creatorDetails.lastName && props.slateRef.current.creatorDetails.firstName !== '' && props.slateRef.current.creatorDetails.lastName !== '' ? (capitalizeFirstAlphabet(props.slateRef.current.creatorDetails.firstName) + ' ' + capitalizeFirstAlphabet(props.slateRef.current.creatorDetails.lastName).substring(0, 13)) : (props.slateRef.current.creatorDetails.email).substring(0, 13)}
                                </Typography>

                            </Box>}
                    </Box>
                    <Box sx={{
                        width: '70%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: "right",
                        alignItems: 'right'
                    }}>
                        <DisplayAvatars profileUrlList={profileUrlList} isTeacher={props.isTeacher} />
                        {props.slateRef && props.slateRef.current && props.isTeacher && <>
                            {/* {(slateVisibility === "private" || slateVisibility === "public") && <Tooltip title={slateVisibility === "private" ? "Make slate public" : "Make slate private"}>
                                                <IconButton
                                                    sx={{
                                                        color: THEME_COLOR,
                                                        marginRight: '20px',
                                                        borderRadius: '5px',
                                                        border: '1.5px solid #D3D3D3',
                                                        marginTop: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                                                        marginBottom: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                                                        width: { xs: '20px', sm: '40px', md: '40px', lg: '60px' }
                                                    }}
                                                    size='small'
                                                    onClick={(e) => {
                                                        setOpenVisibilityBox(e.currentTarget)
                                                    }}
                                                >
                                                    {slateVisibility === "private" &&
                                                        <LockIcon
                                                            sx={{
                                                                color: '#000000',
                                                                fontSize: { xs: "0.75rem", sm: '1.5rem', md: "1.5rem", lg: '1.5rem', xl: '1.5rem' }
                                                            }}
                                                        />}
                                                    {slateVisibility === "public" && <PublicIcon
                                                        sx={{
                                                            color: '#000000',
                                                            fontSize: { xs: "0.75rem", sm: '1.5rem', md: "1.5rem", lg: '1.5rem', xl: '1.5rem' }
                                                        }}

                                                    />}
                                                </IconButton>
                                            </Tooltip>} */}
                            <Popover
                                open={openVisibilityBox}
                                anchorEl={openVisibilityBox}
                                onClose={() => setOpenVisibilityBox(false)}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', padding: '10px', justifyContent: 'center', textAlign: 'center' }}>
                                    <Typography variant='body1' sx={{
                                        color: THEME_COLOR,
                                    }}>Make slate {slateVisibility === "public" && 'private'}{slateVisibility === "private" && 'public'}?</Typography>
                                    <Box sx={{
                                        paddingTop: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'center',
                                    }}>

                                        <Button
                                            color="secondary"
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                height: '30px',
                                                textTransform: 'none',
                                                background: THEME_COLOR,
                                                '&:hover': {
                                                    backgroundColor: THEME_HOVER_COLOR,
                                                    boxShadow: 'none',
                                                },
                                                marginRight: '10px'
                                            }}
                                            onClick={() => {
                                                setOpenVisibilityBox(false)
                                            }}
                                        >
                                            No
                                        </Button>
                                        <Button
                                            color="secondary"
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                height: '30px',
                                                textTransform: 'none',
                                                background: THEME_COLOR,
                                                '&:hover': {
                                                    backgroundColor: THEME_HOVER_COLOR,
                                                    boxShadow: 'none',
                                                },
                                            }}
                                            onClick={() => {
                                                if (slateVisibility === "public") {
                                                    changeVisibilityOfSlate('private', props.accessToken)
                                                }
                                                else if (slateVisibility === "private") {
                                                    changeVisibilityOfSlate('public', props.accessToken)
                                                }

                                                setOpenVisibilityBox(false)
                                            }}
                                        >
                                            Yes
                                        </Button>
                                    </Box>

                                </Box>
                            </Popover>
                        </>}
                        {props.isTeacher && props.slateID && <EditSlate accessToken={props.accessToken} slateID={props.slateID} isTextFocused={props.isTextFocused} sendLessonEventViaWebSocket={props.sendLessonEventViaWebSocket} />}
                        <Tooltip title="Internet Speed">
                            <IconButton
                                sx={{
                                    display: { xs: 'none', sm: 'block', md: 'block', lg: 'block' },
                                    color: THEME_COLOR,
                                    marginRight: '20px',
                                    borderRadius: '5px',
                                    border: '1.5px solid #D3D3D3',
                                    marginTop: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                                    marginBottom: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                                    width: { xs: '20px', sm: '40px', md: '40px', lg: '60px' }
                                }}
                                onClick={(e) => {
                                    setOpenInternetBox(e.currentTarget)

                                }}
                                size='small'
                            >
                                <img alt='fast_webiste' height='100%' width='100%' src={fast}></img>
                                {/* <NetworkCheckIcon /> */}
                            </IconButton>
                        </Tooltip>
                        {openInternetBox &&
                            <Box sx={{
                                position: 'fixed',
                                top: '90px',
                                right: 330,
                                boxShadow: "4",
                                borderRadius: '5px',
                                background: 'white',
                                zIndex: 99
                            }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', position: 'relative', }}>
                                    <IconButton
                                        sx={{ color: 'black', position: 'absolute', zIndex: 10000, top: 0, right: 0 }}
                                        onClick={() => {
                                            setOpenInternetBox(false)
                                        }}
                                        size="small">
                                        <CloseIcon sx={{ fontSize: '1rem' }} />
                                    </IconButton>

                                    <iframe
                                        src="https://fast.com/"
                                        title="Internet Speed"
                                        width="300"
                                        height="300"
                                        style={{ border: 'none', paddingRight: '15px', paddingTop: '15px', paddingBottom: '5px' }}
                                    ></iframe>
                                </Box>
                            </Box>
                        }
                        <TechSupport
                            isSearchFocus={props.isSearchFocus}
                            whiteBoardID={props.whiteBoardID}
                            accessToken={props.accessToken}
                            userName={props.userName}
                            slateRef={props.slateRef}
                        />

                        <Tooltip title="Reactions">
                            <IconButton
                                sx={{
                                    color: '#000000',
                                    marginRight: '20px',
                                    borderRadius: '5px',
                                    border: '1.5px solid #D3D3D3',
                                    marginTop: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                                    marginBottom: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                                    width: { xs: '20px', sm: '40px', md: '40px', lg: '60px' }
                                }}
                                onClick={(e) => {
                                    setOpenSmileyBox(e.currentTarget)

                                }}
                                size='small'
                            >
                                <AddReactionIcon sx={{ fontSize: { xs: "0.75rem", sm: '1.5rem', md: "1.5rem", lg: '1.5rem', xl: '1.5rem' } }} />
                            </IconButton>
                        </Tooltip>
                        <Popover
                            open={openSmileyBox}
                            anchorEl={openSmileyBox}
                            onClose={() => setOpenSmileyBox(false)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingRight: '10px' }}>
                                {SmileyCollection.map((element, index) => {
                                    return (
                                        <Box
                                            key={index}
                                            sx={{ height: '50px', width: '50px', fontSize: "1.5rem" }} >
                                            <Button sx={{
                                                height: '100%', width: '100%', fontSize: "1.5rem"
                                            }}
                                                onClick={() => {
                                                    sendSmiley(props.userName, element, props.userName)
                                                    setOpenSmileyBox(false)
                                                }}
                                            >
                                                {element}
                                            </Button>

                                        </Box>
                                    )
                                })}
                            </Box>
                        </Popover>
                        {props.isTeacher && <PermissionDrawerButton sendLessonEventViaWebSocket={props.sendLessonEventViaWebSocket} />}
                        {/* <Tooltip title='Share file with other members'>
                                            <IconButton
                                                sx={{
                                                    color: '#000000',
                                                    marginRight: '20px',
                                                    borderRadius: '5px',
                                                    border: '1.5px solid #D3D3D3',
                                                    marginTop: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                                                    marginBottom: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                                                    width: { xs: '20px', sm: '40px', md: '40px', lg: '60px' }
                                                }}
                                                onClick={() => { document.getElementById("file-to-upload-and-share").click() }}
                                                size='small'>
                                                <ShareIcon sx={{ fontSize: { xs: "0.75rem", sm: '1.5rem', md: "1.5rem", lg: '1.5rem', xl: '1.5rem' } }} />
                                            </IconButton>
                                        </Tooltip> */}
                        <input
                            type="file"
                            id="file-to-upload-and-share"
                            accept="image/*,.pdf,audio/*,video/*"
                            hidden
                            onChange={(e) => {
                                handleUpload(e, props.accessToken, props.handleChangeLoading)

                            }}
                        />
                        {/* <Popover
                                open={openShareFileLink}
                                anchorEl={openShareFileLink}
                                onClose={() => {
                                    setOpenShareFileLink(false)
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <Box sx={{
                                    backgroundColor: '#fafafa',
                                    border: '1px solid #e0e0e0',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    paddingTop: '10px',
                                    paddingBottom: '10px',
                                    width: '400px',
                                    boxShadow: '2',
                                    position: 'relative'
                                }}>
                                    <IconButton
                                        sx={{ color: 'black', position: 'absolute', zIndex: 10000, top: 0, right: 0 }}
                                        onClick={() => {
                                            setOpenShareFileLink(false)
                                            setFileLink('')
                                        }}
                                        size="small">
                                        <CloseIcon />
                                    </IconButton>
                                    <Box sx={{
                                        paddingTop: '10px', display: 'flex', justifyContent: 'center', width: '100%', paddingBottom: '10px',
                                    }}>
                                        <OutlinedInput
                                            sx={{
                                                width: '80%', borderRadius: '75px',
                                                '& .MuiAutocomplete-inputRoot': {
                                                    borderRadius: '25px',
                                                },
                                            }}
                                            id='fileLink' type="text" placeholder="File Link" value={fileLink} onChange={(e) => setFileLink(e.target.value)} name="fileLink" required
                                        />

                                    </Box>
                                    <Box sx={{
                                        paddingTop: '10px', display: 'flex', justifyContent: 'center', width: '100%', paddingBottom: '10px'
                                    }}>

                                        <Button
                                            color="secondary"
                                            type="submit"
                                            variant="contained"
                                            sx={{
                                                background: THEME_COLOR,
                                                '&:hover': {
                                                    backgroundColor: THEME_HOVER_COLOR,
                                                    boxShadow: 'none',
                                                },
                                            }}
                                            onClick={() => {
                                                handleShareFile(props.accessToken)
                                                setFileLink('')
                                            }}
                                        >
                                            Share File
                                        </Button>
                                    </Box>
                                </Box>
                            </Popover> */}

                        <Tooltip title='Invite Link'>
                            <IconButton
                                sx={{
                                    color: '#000000',
                                    marginRight: { xs: 0, sm: 0, md: 0, lg: '20px', xl: '20px' },
                                    borderRadius: '5px',
                                    border: '1.5px solid #D3D3D3',
                                    marginTop: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                                    marginBottom: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                                    width: { xs: '20px', sm: '40px', md: '40px', lg: '60px' }
                                }}
                                onClick={(event) => {
                                    navigator.clipboard.writeText(FRONTEND_URL + 'slate/' + props.slateID)
                                    setOpenCopiedToClipboard(event.currentTarget)
                                    setTimeout(() => {
                                        setOpenCopiedToClipboard(false)
                                    }, 500)
                                }
                                }
                            >

                                <InsertLinkIcon sx={{ transform: 'rotate(145deg)', fontSize: { xs: "0.75rem", sm: '1.5rem', md: "1.5rem", lg: '1.5rem', xl: '1.5rem' } }} />
                            </IconButton>
                        </Tooltip>
                        <Popover
                            open={openCopiedToClipboard}
                            anchorEl={openCopiedToClipboard}
                            onClose={() => {
                                setOpenCopiedToClipboard(false)
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <Chip label="Copied" />
                        </Popover>
                        <Tooltip title='Maximize/Minimize'>
                            <IconButton
                                sx={{
                                    color: '#000000',
                                    // marginRight: '20px',
                                    borderRadius: '5px',
                                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' },
                                    border: '1.5px solid #D3D3D3',
                                    marginTop: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                                    marginBottom: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                                    width: { xs: '20px', sm: '40px', md: '40px', lg: '60px' }
                                }}
                                onClick={(event) => {
                                    if (document.fullscreenElement !== null) {
                                        closeFullscreen()
                                    }
                                    else {
                                        openFullscreen()
                                    }

                                }
                                }
                            >
                                <AspectRatioIcon sx={{ fontSize: { xs: "0.75rem", sm: '1.5rem', md: "1.5rem", lg: '1.5rem', xl: '1.5rem' } }} />

                            </IconButton>
                        </Tooltip>
                        {props.isTeacher ? endSessionButton : exitSessionButton}
                        {/* <Tooltip title='Minimize'>
                                            <IconButton
                                                sx={{
                                                    color: '#000000',
                                                    borderRadius: '5px',
                                                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' },
                                                    border: '1.5px solid #D3D3D3',
                                                    marginTop: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                                                    marginBottom: { xs: "20px", sm: '10px', md: "10px", lg: '10px', xl: '10px' },
                                                    width: { xs: '20px', sm: '40px', md: '40px', lg: '60px' }
                                                }}
                                                onClick={(event) => {
                                                    closeFullscreen()
                                                }
                                                }
                                            >
                                                <FullscreenExitIcon sx={{ fontSize: { xs: "0.75rem", sm: '1.5rem', md: "1.5rem", lg: '1.5rem', xl: '1.5rem' } }} />
                                            </IconButton>
                                        </Tooltip> */}
                    </Box>

                </Drawer>}
            </Box>
            {<>
                {showTopBar ? <IconButton sx={{
                    position: 'fixed',
                    top: '70px',
                    left: '49%',
                    color: '#000000',
                    backgroundColor: '#ffffff',
                    borderRadius: '0 0 5px 5px',
                    boxShadow: 1,
                    width: '30px',
                    height: "10px",
                    zIndex: 10,
                    fontSize: '1rem',
                    "&:hover": { backgroundColor: "rgb(255,255,255,0.8)" }
                }}

                    onClick={() => {
                        setShowTopBar(false)
                    }}
                >
                    <ArrowDropUpIcon />
                </IconButton> :
                    <IconButton sx={{
                        position: 'fixed',
                        top: 0,
                        left: '49%',
                        color: '#000000',
                        backgroundColor: '#ffffff',
                        borderRadius: '0 0 5px 5px',
                        boxShadow: 1,
                        width: '30px',
                        height: "10px",
                        zIndex: 10,
                        fontSize: '1rem',
                        "&:hover": { backgroundColor: "rgb(255,255,255,0.8)" }
                    }}

                        onClick={() => {
                            setShowTopBar(true)
                        }}
                    >
                        <ArrowDropDownIcon />
                    </IconButton>}
            </>}

            <Box sx={{ position: 'fixed' }}>
                {/* {<Tooltip title={"View/Paste Uploads"} placement="right">
                    <IconButton
                        sx={{ position: 'fixed', left: '15px', top: '35%', color: "#ffffff", zIndex: 100 }}
                        onClick={async (event) => {

                            setDirectoryBox(event.currentTarget)
                            let status, res;
                            try {
                                res = await getUserFiles(props.accessToken);;
                                status = res.status
                            }

                            catch (err) {
                                console.log(err);
                                status = err.response.status;
                            }
                            // console.log(index)
                            if (status === 200) {
                                // console.log(res)
                                setUploadedFilesArray(res.data.files)
                                setFilteredUploadFiles(res.data.files)
                            }
                            else {
                                console.log(status)
                                if (status === 400)
                                    alert('No Uploads Yet')
                            }
                            // console.log(pdfDirectoryArray)

                        }
                        }
                        size="medium">
                        <ContentPasteGoIcon />
                    </IconButton>
                </Tooltip>} */}

                <>
                    <Drawer
                        sx={{
                            width: { xs: '60%', sm: '35%', md: '30%', lg: '25%' },
                            flexShrink: 0,
                            zIndex: 1500,
                            '& .MuiDrawer-paper': {
                                width: { xs: '60%', sm: '35%', md: '30%', lg: '25%' },
                                boxSizing: 'border-box',
                            },
                        }}
                        variant="persistent"
                        anchor="left"
                        open={directoryBox}
                    >

                        <Grid container sx={{ color: 'black', height: '15px', position: 'sticky', right: '5px', top: "5px", zIndex: 10000 }} justifyContent='right' textAlign='right'>
                            <IconButton
                                sx={{
                                    display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' },
                                    marginRight: '5px', color: 'black', position: 'sticky', zIndex: 10000, height: '1.2rem', width: '1.2rem'
                                }}
                                onClick={() => {
                                    setDirectoryBox(false);
                                    setSearchText('')
                                }}
                            >
                                <CloseIcon sx={{ fontSize: '1rem' }} />
                            </IconButton>
                        </Grid>
                        <Box sx={{ minHeight: '50px', width: '100%', paddingTop: '10px', paddingLeft: '10px', marginBottom: '10px', justifyContent: 'center', textAlign: "center" }}>

                            <Typography variant='h5' sx={{ color: 'black', fontWeight: '600' }}>
                                Content Library
                            </Typography>
                        </Box>

                        <Box sx={{
                            minHeight: '70px',
                            backgroundColor: '#fafafa',
                            border: '1px solid #e0e0e0',
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                            <Button
                                sx={{ textTransform: 'none', width: "50%", color: "black", background: fileType === 'Other' ? 'white' : 'none', boxShadow: fileType === 'Other' ? 1 : 0 }}
                                onClick={() => {
                                    setFileType('Other')
                                }}
                            >
                                <Typography variant="body1">
                                    My Files
                                </Typography>

                            </Button>
                            <Button
                                sx={{ textTransform: 'none', width: "50%", color: "black", background: fileType === 'Whiteboard' ? 'white' : 'none', boxShadow: fileType === 'Whiteboard' ? 1 : 0 }}
                                onClick={() => {
                                    setFileType('Whiteboard')

                                }}
                            >
                                <Typography variant="body1">
                                    Whiteboard Files
                                </Typography>

                            </Button>
                        </Box>
                        <Box sx={{ height: '50px', marginBottom: "10px", marginTop: "15px", justifyContent: 'center', textAlign: "center", alignItems: 'center' }}>
                            <Paper
                                component="form"
                                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', margin: "0 5px", }}
                            >
                                <InputBase
                                    sx={{ ml: 1, flex: 1 }}
                                    placeholder="Search"
                                    value={searchText}
                                    onChange={(e) => {
                                        setSearchText(e.target.value)
                                        findSearchTextInFiles(e.target.value)
                                    }}
                                    onFocus={() => {
                                        props.isSearchFocus.current = true;
                                        // console.log(props.isSearchFocus.current)
                                    }}
                                    onBlur={() => {
                                        props.isSearchFocus.current = false;
                                        // console.log(props.isSearchFocus.current)
                                    }}
                                />
                                <IconButton type="button" sx={{ p: '10px' }} disabled size='small'>
                                    <SearchIcon />
                                </IconButton>

                            </Paper>
                        </Box>
                        <Grid container sx={{ marginTop: '25px', display: 'flex', flexDirection: 'column', paddingLeft: '10px' }}>
                            {filteredUploadFiles.map((element) => {
                                return <>{(fileType === 'Other') && !element.includes('_whiteBoardID:') &&
                                    <TableRow sx={{
                                        maxWidth: '100%',
                                        background: '#fafafa',
                                        margin: "5px 5px",
                                        boxShadow: "1",
                                    }}>
                                        <a
                                            style={{ cursor: "pointer", textDecoration: 'none' }} onClick={async (e) => {
                                                const fileUrl = UPLOAD_API_URL + 'uploads/' + props.clientID + '/' + element
                                                setUploadedFileUrl(fileUrl)
                                                setUploadedFileName(element)
                                                setOpenConfirmationForUpload(e.currentTarget)
                                            }
                                            }>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                                sx={{ paddingLeft: '5px' }}
                                            >
                                                {element}
                                            </Typography>
                                        </a>
                                    </TableRow>
                                }
                                    {(fileType === 'Whiteboard') && element.includes('_whiteBoardID:') && !element.includes(props.whiteBoardID) &&
                                        < TableRow sx={{
                                            maxWidth: '100%',
                                            background: '#fafafa',
                                            margin: "5px 5px",
                                            boxShadow: "1",
                                        }}>
                                            <a
                                                style={{ cursor: "pointer", textDecoration: 'none' }} onClick={async (e) => {
                                                    const fileUrl = UPLOAD_API_URL + 'uploads/' + props.clientID + '/' + element
                                                    setUploadedFileUrl(fileUrl)
                                                    setUploadedFileName(element)
                                                    setOpenConfirmationForUpload(e.currentTarget)
                                                }}>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    sx={{ paddingLeft: '5px' }}
                                                >
                                                    {splitFileName(element)}
                                                </Typography>
                                            </a>
                                        </TableRow >
                                    }

                                </>
                            })}
                        </Grid>
                        <Popover
                            open={openConfirmationForUpload}
                            anchorEl={openConfirmationForUpload}
                            onClose={() => setOpenConfirmationForUpload(false)}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', padding: '10px' }}>
                                <Typography variant='body2' sx={{
                                    color: THEME_COLOR,
                                }}>Do you want to upload file to whiteboard?</Typography>
                                <Box sx={{
                                    paddingTop: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'center',
                                }}>

                                    <Button
                                        color="secondary"
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            height: '30px',
                                            textTransform: 'none',
                                            background: THEME_COLOR,
                                            '&:hover': {
                                                backgroundColor: THEME_HOVER_COLOR,
                                                boxShadow: 'none',
                                            },
                                            marginRight: '10px'
                                        }}
                                        onClick={async () => {
                                            let elementNameArray = uploadedFileName.split('.')
                                            let elementType = elementNameArray[elementNameArray.length - 1]
                                            // console.log(elementType)
                                            if (elementType === 'pdf') {

                                                handleIsPdf(null, uploadedFileUrl)

                                            }
                                            else if (elementType === 'jpeg' || elementType === 'png' || elementType === 'jpg') {
                                                props._sketch.current.addImg(uploadedFileUrl, false)
                                            }
                                            else if (elementType === 'txt') {
                                                props.uploadCanvasFromContentLibrary(uploadedFileUrl)
                                            }
                                            else if (elementType === 'mp4' || elementType === 'mov' || elementType === 'webm') {
                                                // props.openMediaPlayerAndSendEvent(uploadedFileUrl, 'video')
                                                let streamFileUrl = STREAM_FILE_SERVER + 'uploads/' + props.clientID + '/' + uploadedFileName;
                                                props._sketch.current.addAudioVideoTag(streamFileUrl, 'video', uploadedFileName)
                                            }
                                            else if (elementType === 'mp3' || elementType === 'wav') {
                                                // props.openMediaPlayerAndSendEvent(uploadedFileUrl, 'audio')
                                                let streamFileUrl = STREAM_FILE_SERVER + 'uploads/' + props.clientID + '/' + uploadedFileName;
                                                props._sketch.current.addAudioVideoTag(streamFileUrl, 'audio', uploadedFileName)
                                            }
                                            else if (elementType === 'pptx') {
                                                handlePptxDocs(null, 'pptx', uploadedFileUrl)
                                            }
                                            else if (elementType === 'docx') {
                                                handlePptxDocs(null, 'docx', uploadedFileUrl)
                                            }
                                            else if (elementType === 'xlsx') {
                                                handlePptxDocs(null, 'xlsx', uploadedFileUrl)
                                            }
                                            else if (elementType === 'ppt') {
                                                handlePptxDocs(null, 'ppt', uploadedFileUrl)
                                            }
                                            else if (elementType === 'doc') {
                                                handlePptxDocs(null, 'doc', uploadedFileUrl)
                                            }
                                            else if (elementType === 'xls') {
                                                handlePptxDocs(null, 'xls', uploadedFileUrl)
                                            }
                                            setOpenConfirmationForUpload(false)
                                            setDirectoryBox(false)

                                        }}
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        color="secondary"
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            height: '30px',
                                            textTransform: 'none',
                                            background: THEME_COLOR,
                                            '&:hover': {
                                                backgroundColor: THEME_HOVER_COLOR,
                                                boxShadow: 'none',
                                            },

                                        }}
                                        onClick={() => {
                                            setOpenConfirmationForUpload(false)
                                        }}
                                    >
                                        No
                                    </Button>
                                </Box>

                            </Box>
                        </Popover>
                    </Drawer>
                    {directoryBox && <Box
                        sx={{
                            position: 'absolute',
                            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' },
                            top: 'calc(50% - 15px)',
                            left: { xs: '60%', sm: '35%', md: '30%', lg: '25%' },
                            boxShadow: 2,
                            borderRadius: '0 5px 5px 0',
                        }}
                    >
                        <IconButton sx={{
                            color: '#000000',
                            backgroundColor: '#ffffff',
                            borderRadius: '0 5px 5px 0',
                            zIndex: 1001,
                            width: '10px',
                            padding: '35px 10px',
                            "&:hover": { backgroundColor: "rgb(255,255,255,0.8)" }
                        }}

                            onClick={() => {
                                setDirectoryBox(false)
                            }}
                        >
                            <ArrowLeftIcon sx={{ fontSize: '1.75rem', }} />
                        </IconButton>
                    </Box>}
                </>

            </Box>
            <Box>
                <>
                    {<Tooltip title={"Content Library"} placement="right">
                        <IconButton
                            id='content-library-button'
                            sx={{ position: 'fixed', left: '15px', top: '35%', color: WHITEBOARD_ICON_COLOR, zIndex: 100 }}
                            onClick={async (event) => {
                                setContentBox(true)
                            }
                            }
                            size="medium">
                            <ContentPasteGoIcon />
                        </IconButton>
                    </Tooltip>}
                    <Drawer
                        sx={{
                            width: { xs: '100%', sm: '100%', md: '30%', lg: '35%', xl: '30%' },
                            flexShrink: 0,
                            zIndex: 1500,
                            '& .MuiDrawer-paper': {
                                width: { xs: '100%', sm: '100%', md: '30%', lg: '35%', xl: '30%' },
                                boxSizing: 'border-box',

                            },
                        }}
                        variant="persistent"
                        anchor="left"
                        open={contentBox}
                        onMouseOver={() => {
                            window.whiteboardScrollDisable = true
                        }}
                        onMouseOut={() => {
                            window.whiteboardScrollDisable = false
                        }}
                    >
                        <SearchContent
                            isWhiteboard={true}
                            handleIsPdf={handleIsPdf}
                            handlePptxDocs={handlePptxDocs}
                            addImage={props._sketch.current}
                            setContentBox={setContentBox}
                            uploadCanvasFromContentLibrary={props.uploadCanvasFromContentLibrary}
                        />

                    </Drawer>
                    {!props.isViewer && slateDataReceived &&
                        <Chat
                            slateRef={props.slateRef}
                            accessToken={props.accessToken}
                            clientID={props.clientID}
                            isTextFocused={props.isTextFocused}
                            handleIsPdf={handleIsPdf}
                            handlePptxDocs={handlePptxDocs}
                            addImage={props._sketch.current}
                            uploadCanvasFromContentLibrary={props.uploadCanvasFromContentLibrary}
                            isTeacher={props.isTeacher}
                            userName={props.userName}
                        />
                    }
                    {contentBox && <Box
                        sx={{
                            position: 'absolute',
                            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' },
                            top: 'calc(50% - 15px)',
                            left: { xs: '100%', sm: '100%', md: '30%', lg: '35%', xl: '30%' },
                            boxShadow: 2,
                            borderRadius: '0 5px 5px 0',
                        }}
                    >
                        <IconButton sx={{
                            color: '#000000',
                            backgroundColor: '#ffffff',
                            borderRadius: '0 5px 5px 0',
                            zIndex: 1001,
                            width: '10px',
                            padding: '35px 10px',
                            "&:hover": { backgroundColor: "rgb(255,255,255,0.8)" }
                        }}

                            onClick={() => {
                                setContentBox(false)
                                window.whiteboardScrollDisable = false
                            }}
                        >
                            <ArrowLeftIcon sx={{ fontSize: '1.75rem', }} />
                        </IconButton>
                    </Box>}
                </>
                <Tooltip title='Apps' placement="right">
                    <IconButton
                        id='apps-left-button'
                        sx={{ position: 'fixed', left: '15px', top: '40%', color: WHITEBOARD_ICON_COLOR, zIndex: 100 }}
                        onClick={() => {
                            setOpenApps(!openApps)
                        }
                        }
                        size="medium">

                        <AppsIcon />
                    </IconButton>
                </Tooltip>
                <>
                    <Drawer
                        sx={{
                            width: { xs: '50%', sm: '35%', md: '30%', lg: '25%', xl: '20%' },
                            flexShrink: 0,
                            zIndex: 1200,
                            '& .MuiDrawer-paper': {
                                width: { xs: '50%', sm: '35%', md: '30%', lg: '25%', xl: '20%' },
                                boxSizing: 'border-box',
                            },
                        }}
                        variant="persistent"
                        anchor="left"
                        open={openApps}
                    >
                        <Grid container sx={{ color: 'black', height: '15px', position: 'sticky', right: '5px', top: "5px", zIndex: 10000 }} justifyContent='right' textAlign='right'>
                            <IconButton
                                sx={{
                                    display: 'block',
                                    color: 'black',
                                    position: 'sticky',
                                    zIndex: 10000
                                }}
                                onClick={() => {
                                    setOpenApps(false);
                                }}
                                size="small">
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                        <Box sx={{ height: '7.5%', width: '100%', fontWeight: '500', }}>
                            <IconButton disabled sx={{ paddingTop: '3%' }}>
                                <AppsIcon size='small' />
                            </IconButton>  Apps
                        </Box>
                        <Box sx={{ height: '1px', background: '#D3D3D3' }}></Box>
                        <Grid container sx={{ marginTop: '25px' }}>
                            <Grid item sm={12} md={12} lg={12}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography sx={{ color: '#808080' }}>Graphs</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: '20%' }}>
                                        <Button
                                            sx={{ justifyContent: 'center', textAlign: 'center' }}
                                            onClick={() => {
                                                props._sketch.current.addImg(graph1, false)
                                            }}>
                                            <img
                                                alt='graph_1'
                                                src={graph1}
                                                height='100px'
                                                width='80%'
                                            ></img>
                                        </Button>
                                        <Button
                                            sx={{ justifyContent: 'center', textAlign: 'center', paddingLeft: '15%' }}
                                            onClick={() => {
                                                props._sketch.current.addImg(graph2, false)
                                            }}>
                                            <img
                                                alt='graph_2'
                                                src={graph2}
                                                height='100px'
                                                width='80%'
                                            ></img>
                                        </Button>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item sm={12} md={12} lg={12}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography sx={{ color: '#808080' }}>Fraction</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: '10%' }}>
                                        <Button
                                            sx={{ justifyContent: 'center', textAlign: 'center' }}
                                            onClick={() => {
                                                props._sketch.current.addImg(fraction1by2, false)
                                            }}>
                                            <img
                                                alt='fraction1by2'
                                                src={fraction1by2}
                                                height='50px'
                                                width='80%'
                                            ></img>
                                        </Button>
                                        <Button
                                            sx={{ justifyContent: 'center', textAlign: 'center' }}
                                            onClick={() => {
                                                props._sketch.current.addImg(fractionWhole, false)
                                            }}>
                                            <img
                                                alt='fractionWhole'
                                                src={fractionWhole}
                                                height='50px'
                                                width='80%'
                                            ></img>
                                        </Button>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item sm={12} md={12} lg={12}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography sx={{ color: '#808080' }}>Protractor</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: '10%' }}>
                                        <Button
                                            sx={{ justifyContent: 'center', textAlign: 'center' }}
                                            onClick={() => {
                                                props._sketch.current.addImg(protractor, false)
                                            }}>
                                            <img
                                                alt='protractor'
                                                src={protractor}
                                                height='100px'
                                                width='80%'
                                            ></img>
                                        </Button>
                                        <Button
                                            sx={{ justifyContent: 'center', textAlign: 'center' }}
                                            onClick={() => {
                                                props._sketch.current.addImg(protractor2, false)
                                            }}>
                                            <img
                                                alt='protractor2'
                                                src={protractor2}
                                                height='100px'
                                                width='80%'
                                            ></img>
                                        </Button>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item sm={12} md={12} lg={12}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography sx={{ color: '#808080' }}>Shapes</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: '20%' }}>
                                        <Button
                                            sx={{ justifyContent: 'center', textAlign: 'center' }}
                                            onClick={() => {
                                                props._sketch.current.addImg(cube3d, false)
                                            }}>
                                            <img
                                                alt='cube3d'
                                                src={cube3d}
                                                height='100px'
                                                width='80%'
                                            ></img>
                                        </Button>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item sm={12} md={12} lg={12}>
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography sx={{ color: '#808080' }}>Ruler</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ padding: '10%' }}>
                                        <Button
                                            sx={{ justifyContent: 'center', textAlign: 'center' }}
                                            onClick={() => {
                                                props._sketch.current.addImg(ruler, false)
                                            }}>
                                            <img
                                                alt='ruler'
                                                src={ruler}
                                                height='50px'
                                                width='90%'
                                            ></img>
                                        </Button>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </Drawer>
                    {openApps && <Box
                        sx={{
                            position: 'absolute',
                            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' },
                            top: 'calc(50% - 15px)',
                            left: { xs: '50%', sm: '35%', md: '30%', lg: '25%', xl: '20%' },
                            boxShadow: 2,
                            borderRadius: '0 5px 5px 0',
                        }}
                    >
                        <IconButton sx={{
                            color: '#000000',
                            backgroundColor: '#ffffff',
                            borderRadius: '0 5px 5px 0',
                            zIndex: 1001,
                            width: '10px',
                            padding: '35px 10px',
                            "&:hover": { backgroundColor: "rgb(255,255,255,0.8)" }
                        }}

                            onClick={() => {
                                setOpenApps(false)
                            }}
                        >
                            <ArrowLeftIcon sx={{ fontSize: '1.75rem', }} />
                        </IconButton>
                    </Box>
                    }
                </>
            </Box>
            {/* Left toolbar */}
            <Box sx={{ width: '100%', display: { xs: 'flex', sm: 'none', md: 'none', lg: 'none' } }}>
                {showLeftToolbar ? <IconButton sx={{
                    position: 'absolute',
                    top: '40%',
                    left: '70px',
                    color: '#ffffff',
                    backgroundColor: WHITEBOARD_TOOLBAR_COLOR,
                    borderRadius: '0 5px 5px 0',
                    zIndex: 100,
                    width: '10px',
                    padding: '20px 10px',
                    fontSize: '1rem',
                    "&:hover": { backgroundColor: WHITEBOARD_TOOLBAR_COLOR }
                }}

                    onClick={() => {
                        setShowLeftToolbar(false)
                        if (document.getElementById('slate-pad-button')) {
                            console.log(document.getElementById('slate-pad-button').style.display)
                            document.getElementById('slate-pad-button').style.display = 'none'
                        }
                        if (document.getElementById('periodic-table-button')) {
                            document.getElementById('periodic-table-button').style.display = 'none'
                        }
                        if (document.getElementById('timer-button')) {
                            document.getElementById('timer-button').style.display = 'none'
                        }
                        if (document.getElementById('desmos-button')) {
                            document.getElementById('desmos-button').style.display = 'none'
                        }
                        if (document.getElementById('apps-left-button')) {
                            document.getElementById('apps-left-button').style.display = 'none'
                        }
                        if (document.getElementById('content-library-button')) {
                            document.getElementById('content-library-button').style.display = 'none'
                        }
                        if (document.getElementById('screenshot-button')) {
                            document.getElementById('screenshot-button').style.display = 'none'
                        }
                        if (document.getElementById('left-toolbar')) {
                            document.getElementById('left-toolbar').style.display = 'none'
                        }
                    }}
                >
                    <ArrowLeftIcon />

                </IconButton> :
                    <IconButton sx={{
                        position: 'absolute',
                        top: '40%',
                        left: '0px',
                        color: '#ffffff',
                        backgroundColor: WHITEBOARD_TOOLBAR_COLOR,
                        borderRadius: '0 5px 5px 0',
                        zIndex: 1001,
                        width: '10px',
                        padding: '20px 10px',
                        "&:hover": { backgroundColor: WHITEBOARD_TOOLBAR_COLOR }
                    }}

                        onClick={() => {
                            setShowLeftToolbar(true)
                            if (document.getElementById('slate-pad-button')) {
                                document.getElementById('slate-pad-button').style.display = 'block'
                            }
                            if (document.getElementById('periodic-table-button')) {
                                document.getElementById('periodic-table-button').style.display = 'block'
                            }
                            if (document.getElementById('timer-button')) {
                                document.getElementById('timer-button').style.display = 'block'
                            }
                            if (document.getElementById('desmos-button')) {
                                document.getElementById('desmos-button').style.display = 'block'
                            }
                            if (document.getElementById('apps-left-button')) {
                                document.getElementById('apps-left-button').style.display = 'block'
                            }
                            if (document.getElementById('content-library-button')) {
                                document.getElementById('content-library-button').style.display = 'block'
                            }
                            if (document.getElementById('screenshot-button')) {
                                document.getElementById('screenshot-button').style.display = 'block'
                            }
                            if (document.getElementById('left-toolbar')) {
                                document.getElementById('left-toolbar').style.display = 'block'
                            }
                        }}
                    >
                        <ArrowRightIcon />
                    </IconButton>}
            </Box>
            {!props.isViewer &&
                <Box
                    id='left-toolbar'
                    sx={{
                        zIndex: 10,
                        position: 'fixed',
                        left: { xs: 0, sm: '10px', md: '10px', lg: '10px' },
                        // top: props.isTeacher ? { xs: "27.5%", sm: '27.5%', md: "27.5%", lg: '27.5%', xl: '27.5%' } : '27.5%',
                        top: props.isTeacher ? '22.5%' : '27.5%',
                        width: { xs: '70px', sm: '60px', md: '60px', lg: '60px' },
                        // height: props.isTeacher ? { xs: "35%", sm: '35%', md: "35%", lg: '35%', xl: '35%' } : { xs: "35%", sm: '35%', md: "35%", lg: '35%', xl: '35%' },
                        height: props.isTeacher ? '40%' : '35%',
                        backgroundColor: WHITEBOARD_TOOLBAR_COLOR,
                        padding: '10px',
                        borderRadius: { xs: '0 10px 10px 0', sm: '10px', md: '10px', lg: '10px' },
                    }}></Box>
            }

            {/* <Tooltip title='Slate Pad' placement="right">
                <IconButton
                    id='slate-pad-button'
                    sx={{ position: 'fixed', left: '15px', top: '55%', color: "#ffffff", zIndex: 100 }}
                    onClick={() => {
                        if (props.slateRef && props.slateRef.current) {
                            props._sketch.current.addIframeTag("https://server1.slatemates.in:9001/p/" + props.slateRef.current.id + "?showControls=false&showChat=false&showLineNumbers=true&useMonospaceFont=false", true)
                        }
                    }}
                    size="medium">

                    <AssignmentIcon />
                </IconButton>
            </Tooltip> */}

            <Tooltip title='Desmos Calculator' placement="right">
                <IconButton
                    id='desmos-button'
                    sx={{ position: 'fixed', left: '15px', top: '30%', color: iconColor, zIndex: 100 }}
                    onClick={() => {
                        setOpenCalculator(true)
                    }
                    }
                    size="medium">

                    <CalculateIcon />
                </IconButton>
            </Tooltip>
            {openCalculator &&
                <Box sx={{
                    position: 'fixed',
                    left: '80px',
                    top: '10%',
                    boxShadow: "4",
                    borderRadius: '5px',
                    background: 'white',
                    height: 500,
                    width: 500,
                    zIndex: 99,
                    resize: 'both',
                    overflow: 'auto',
                }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', position: 'relative', height: '100%', width: '100%' }}>
                        <IconButton
                            sx={{ color: 'white', position: 'absolute', zIndex: 10000, top: 0, right: 0 }}
                            onClick={() => {
                                setOpenCalculator(false)
                            }}
                            size="small">
                            <CloseIcon sx={{ fontSize: '1rem' }} />
                        </IconButton>
                        <Box sx={{ width: '100%', textAlign: 'center', justifyContent: 'center', color: '#ffffff', background: '#2a2a2a', fontSize: '0.8rem' }}>
                            Calculator is only visible to you
                        </Box>
                        <iframe
                            id='desmos-calculator-iframe'
                            src="https://www.desmos.com/calculator"
                            title="Internet Speed"
                            height='95%'
                            width='100%'

                            style={{ border: 'none', paddingBottom: '5px' }}
                        ></iframe>
                        {/* <Button sx={{ height: '10%' }}
                            onClick={() => {
                                const screenshotTarget = document.getElementById('desmos-calculator-iframe')

                                html2canvas(screenshotTarget, {
                                    scale: 1
                                }).then(async (canvas) => {
                                    const dataurl = canvas.toDataURL("image/png")
                                    props._sketch.current.addImg(dataurl, false)

                                });
                            }}
                        >
                            Paste to whiteboard
                        </Button> */}
                    </Box>
                </Box>
            }
            <Box sx={[{ zIndex: 10, display: "flex", flexGrow: 0, flexWrap: "wrap", position: 'fixed', top: '50%', left: '15px', }, props.isLoading && { visibility: "hidden" }]}>
                <Tooltip title='Screenshot' placement="right">
                    <IconButton
                        id='screenshot-button'
                        sx={{ color: WHITEBOARD_ICON_COLOR }}
                        onClick={(event) => {
                            setOpenScreenshotToNotesBox(event.currentTarget)
                        }}
                        size="medium">
                        <ScreenshotMonitorIcon />
                    </IconButton>
                </Tooltip>
                <Popover
                    open={openScreenshotToNotesBox}
                    anchorEl={openScreenshotToNotesBox}
                    onClose={() => setOpenScreenshotToNotesBox(false)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Box sx={{ position: 'relative', overscrollBehavior: "none", maxHeight: '100%', maxWidth: '250px', minHeight: '25px' }} >
                        <Grid container sx={{ color: 'black', height: '20px', position: 'sticky', right: 0, top: 0, zIndex: 10000, width: '100%' }} justifyContent='right' textAlign='right'>

                            <IconButton
                                sx={{ color: 'black', position: 'sticky', zIndex: 10000 }}
                                onClick={() => {
                                    setOpenScreenshotToNotesBox(false)
                                }}
                                size="small">
                                <CloseIcon sx={{ fontSize: '1rem' }} />
                                {/* <CloseIcon sx={{ fontSize: '1rem' }} /> */}
                            </IconButton>

                        </Grid>
                        <Box sx={{ padding: '10px 15px' }}>

                            <Table sx={{ tableLayout: "fixed" }} >

                                <TableBody >

                                    <TableRow sx={{
                                        maxWidth: '100%',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            boxShadow: 1,
                                        },
                                    }}
                                        onClick={(event) => {
                                            const screenshotTarget = document.getElementById('whiteboard-screenshot-box')

                                            html2canvas(screenshotTarget, {
                                                scale: 1
                                            }).then(async (canvas) => {
                                                const dataurl = canvas.toDataURL("image/png")

                                                props.saveScreenshotToNotes(dataurl, 'notes/' + props.clientID + '/', props.accessToken)



                                            });
                                            setOpenAddedToNotes(event.currentTarget)
                                            setTimeout(() => {
                                                setOpenAddedToNotes(false)
                                            }, 2000)
                                        }}
                                    >
                                        <TableCell align="left" sx={{ padding: '2px 5px', color: '#000000', width: '80%', borderBottom: "none", wordWrap: "break-word", fontWeight: 540, fontSize: "0.8rem" }}>
                                            Add screenshot to notes
                                        </TableCell>
                                        <TableCell align="left" sx={{ color: '#828282', width: '20%', padding: 0, borderBottom: "none" }}>
                                            <ScreenshotMonitorIcon sx={{ color: THEME_COLOR, zIndex: 100 }} />
                                        </TableCell>
                                    </TableRow>
                                    <Box sx={{ height: '10px' }}>
                                    </Box>
                                    <TableRow sx={{
                                        maxWidth: '100%',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            boxShadow: 1,
                                        },
                                    }}
                                        onClick={(event) => {
                                            setOpenGPT(true)
                                        }}
                                    >
                                        <TableCell align="left" sx={{ padding: '2px 5px', color: '#000000', width: '80%', borderBottom: "none", wordWrap: "break-word", fontWeight: 540, fontSize: "0.8rem" }}>
                                            GPT Chat
                                        </TableCell>
                                        <TableCell align="left" sx={{ color: '#828282', width: '20%', padding: 0, borderBottom: "none" }}>
                                            <ChatIcon sx={{ color: THEME_COLOR, zIndex: 100 }} />
                                        </TableCell>
                                    </TableRow>
                                    <Box sx={{ height: '10px' }}>
                                    </Box>
                                    <ScreenCapture onEndCapture={handleScreenCapture}>
                                        {({ onStartCapture }) => (
                                            <TableRow sx={{
                                                maxWidth: '100%',
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    boxShadow: 1,
                                                },
                                            }}
                                                onClick={onStartCapture}
                                            >
                                                <TableCell align="left" sx={{ padding: '2px 5px', color: '#000000', width: '80%', borderBottom: "none", wordWrap: "break-word", fontWeight: 540, fontSize: "0.8rem" }}>
                                                    Clip and ask GPT (N)
                                                </TableCell>
                                                <TableCell align="left" sx={{ color: '#828282', width: '20%', padding: 0, borderBottom: "none" }}>
                                                    <ContentCutIcon sx={{ color: THEME_COLOR, zIndex: 100 }} />
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </ScreenCapture>
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                </Popover>

                <Popover
                    open={openAddedToNotes}
                    anchorEl={openAddedToNotes}
                    onClose={() => {
                        setOpenAddedToNotes(false)

                    }}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                >
                    <Box sx={{ backgroundColor: "#525252" }}>
                        <Typography sx={{ fontSize: '0.8rem', color: '#FFFFFF', padding: '5px', fontWeight: 500 }}>
                            Screenshot added to notes
                        </Typography>
                    </Box>

                </Popover>
                {/* Added screencapture so that it can be called using shortcut and its display is set to none so it is not visible */}

                <ScreenCapture onEndCapture={handleScreenCapture}>
                    {({ onStartCapture }) => (
                        <IconButton
                            id="clipping-button"
                            sx={{ color: THEME_COLOR, zIndex: 100, display: 'none' }}
                            onClick={onStartCapture}
                            size="medium">
                            <ContentCutIcon />
                        </IconButton>
                    )}
                </ScreenCapture>
            </Box>
            <Dialog
                fullWidth
                maxWidth="md"
                open={openGPT}
                fullScreen={fullScreen}
                onMouseOver={() => {
                    window.whiteboardScrollDisable = true;
                }}
                onMouseOut={() => {
                    window.whiteboardScrollDisable = false;
                }}
                sx={{
                    zIndex: 10000,  // Ensure this value is higher than the Popover's z-index
                }}
                keepMounted  // This prevents the child components from being unmounted
            >
                <IconButton
                    sx={{ color: '#000000', position: 'absolute', right: '5px', top: "5px", zIndex: 10001 }}
                    onClick={() => {
                        setOpenGPT(false);
                    }}
                    size="small">
                    <CloseIcon sx={{ fontSize: '1rem' }} />
                </IconButton>

                <DialogContent
                    sx={{
                        padding: 0,
                        backgroundColor: '#f8f8f8',
                        justifyContent: 'center',
                        width: "100%",
                        height: '100%',
                        display: openGPT ? 'block' : 'none' // Control visibility without unmounting
                    }}
                >
                    <UploadContentAI
                        clientID={props.clientID}
                        accessToken={props.accessToken}
                        clippedFileData={clippedFileData}
                        isTextFocused={props.isTextFocused}
                        setOpenGPT={setOpenGPT}
                        _sketch={props._sketch}
                        _selectTool={_selectTool}
                    />
                </DialogContent>
            </Dialog>
            {/* <Box sx={{
                zIndex: 10000,
                position: 'fixed',
                top: '10%',
                left: '10%',
                width: '80%',
                borderRadius: '10px',
                visibility: openGPT ? 'visible' : "hidden"
            }}
                onMouseOver={() => {
                    window.whiteboardScrollDisable = true
                }}
                onMouseOut={() => {
                    window.whiteboardScrollDisable = false
                }}
            >
                <Box sx={{ position: 'relative', borderRadius: '10px', }}>
                    <IconButton
                        sx={{ color: '#ffffff', position: 'absolute', right: '5px', top: "5px", zIndex: 10000 }}
                        onClick={() => {
                            setOpenGPT(false)
                        }}
                        size="small">
                        <CloseIcon sx={{ fontSize: '1rem' }} />
                    </IconButton>



                    <UploadContentAI
                        clientID={props.clientID}
                        accessToken={props.accessToken}
                        clippedFileData={clippedFileData}
                        tabIndex={0}
                    />

                </Box>
            </Box> */}
        </ThemeProvider>
    </>


    )
}

export default React.forwardRef(CustomToolbar);